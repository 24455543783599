import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import router from "next/router";
import { dark } from "stitches.config";
import { v4 as uuid } from "uuid";
import { motionValue } from "framer-motion";
import { createState, createSelectorHook } from "@state-designer/react";
import * as vec from "lib/vec";
import { current } from "immer";
import { initialData, defaultData } from "./data";
import history from "lib/history";
import inputs from "lib/inputs";
import exports from "lib/exports";
import * as commands from "lib/commands";
import AnchorSession from "lib/sessions/AnchorSession";
import HandleSession from "lib/sessions/HandleSession";
import ResizeSession from "lib/sessions/ResizeSession";
import TranslateSession from "lib/sessions/TranslateSession";
import TransformSession from "lib/sessions/TransformSession";
import RotateSession from "lib/sessions/RotateSession";
import MoveSession from "./sessions/MoveSession";
import { ICanvasItems } from "lib/types";
import { getAllSelectedBoundingBox, getSelectedBoundingBox, screenToWorld, throttle } from "utils";
import { roundBounds } from "./bounds-utils";
import migrate from "./migrations";
import clipboard from "./clipboard";
import BrushSession from "./sessions/BrushSession";
export var elms = {};
var state = createState({
  data: initialData,
  on: {
    MOUNTED_ELEMENT: {
      secretlyDo: "mountElement"
    },
    UNMOUNTED_ELEMENT: {
      secretlyDo: "deleteElement"
    },
    UNMOUNTED: {
      "do": "teardown",
      to: "loading"
    },
    EXPORTED: "copySvgToClipboard",
    RESIZED: "setViewport",
    PRESSED_SPACE: "toggleFill",
    RELEASED_SPACE: "toggleFill",
    TOGGLED_FILL: "toggleFill",
    MOVED_POINTER: [{
      secretlyDo: "updateMvPointer"
    }, {
      "if": "hasMiddleButton",
      "do": "panCamera"
    }],
    WHEELED: {
      ifAny: ["hasShift", "isTrackpadZoom"],
      "do": ["zoomCamera", "updateMvPointer"],
      "else": ["wheelPanCamera", "updateMvPointer"]
    },
    STARTED_MOVING_THUMBSTICK: {
      "if": "hasSelection",
      to: "draggingWithThumbstick",
      "else": {
        to: "panningWithThumbstick"
      }
    },
    ZOOMED_TO_FIT: "zoomCameraToFitContent",
    COPIED: "copyToClipboard",
    CUT: ["copyToClipboard", "deleteSelection"],
    OPENED_READ_ONLY_PROJECT: ["setReadonly", "disableHistory"],
    OPENED_EDITABLE_PROJECT: ["clearReadonly", "enableHistory"],
    OPENED_SHARE_LINK: {
      to: "viewingShareLink"
    },
    OPENED_SHARE_LINK_MODAL: {
      to: "shareLinkModal"
    },
    DOWNLOADED_SHARE_LINK: {
      "do": "mergeSharedLinkToLocal"
    },
    TOGGLED_THEME: ["toggleTheme", "loadTheme"]
  },
  initial: "loading",
  states: {
    loading: {
      on: {
        MOUNTED: [{
          "if": "isShareLink",
          "do": ["setReadonly", "disableHistory", "loadSharedProject"],
          "else": ["clearReadonly", "enableHistory", "loadLocalProject"]
        }, "setViewport", {
          "if": "isShareLink",
          "do": "zoomCameraToFitContent"
        }, "loadTheme", "setEvents", {
          to: "ready"
        }]
      }
    },
    ready: {
      on: {
        PASTED: {
          unless: "isReadOnly",
          "do": "startPasteFromClipboard"
        },
        FINISHED_PASTE: "finishPasteFromClipboard",
        CHANGED_CODE: "setCode",
        OPENED_CODE_PANEL: "openCodePanel",
        CLOSED_CODE_PANEL: "closeCodePanel",
        INCREASED_CODE_FONT_SIZE: "increaseCodeFontSize",
        DECREASED_CODE_FONT_SIZE: "decreaseCodeFontSize"
      },
      initial: "selecting",
      states: {
        selecting: {
          initial: "notPointing",
          states: {
            notPointing: {
              onEnter: {
                "do": "clearPointingId"
              },
              on: {
                // Keyboard / Menus
                UNDO: "undo",
                REDO: "redo",
                CANCELLED: "clearSelection",
                SELECTED_ALL: "selectAll",
                DELETED: ["deleteSelection"],
                HARD_RESET: ["hardReset"],
                NUDGED_UP: {
                  "if": "hasSelection",
                  "do": "nudgeUp"
                },
                NUDGED_RIGHT: {
                  "if": "hasSelection",
                  "do": "nudgeRight"
                },
                NUDGED_DOWN: {
                  "if": "hasSelection",
                  "do": "nudgeDown"
                },
                NUDGED_LEFT: {
                  "if": "hasSelection",
                  "do": "nudgeLeft"
                },
                // Pointer
                TOGGLED_CAP: "toggleSelectedNodesCap",
                HIGHLIT_GLOB: "pushHighlightGlob",
                HIGHLIT_NODE: "pushHighlightNode",
                UNHIGHLIT_GLOB: "pullHighlightGlob",
                UNHIGHLIT_NODE: "pullHighlightNode",
                HOVERED_GLOB: "pushHoveredGlob",
                UNHOVERED_GLOB: "pullHoveredGlob",
                HOVERED_NODE: "pushHoveredNode",
                UNHOVERED_NODE: "pullHoveredNode",
                RIGHT_CLICKED_NODE: ["setPointingId", {
                  unless: "pointingSelectedNode",
                  "do": "setPointingToSelectedNodes"
                }],
                POINTED_NODE: ["setPointingId", {
                  "if": "pointingSelectedNode",
                  then: {
                    "if": "hasShift",
                    then: {
                      "do": "pullPointingFromSelectedNodes",
                      to: "notPointing"
                    },
                    "else": {
                      unless: "isReadOnly",
                      "if": "hasMeta",
                      to: "resizingNodes",
                      "else": {
                        to: "pointingSelectedNode"
                      }
                    }
                  },
                  "else": {
                    "if": "hasShift",
                    then: {
                      "do": "pushPointingToSelectedNodes",
                      to: "pointingSelectedNode"
                    },
                    "else": ["setPointingToSelectedNodes", {
                      unless: "isReadOnly",
                      "if": "hasMeta",
                      to: "resizingNodes",
                      "else": {
                        to: "pointingSelectedNode"
                      }
                    }]
                  }
                }],
                RIGHT_CLICKED_GLOB: ["setPointingId", {
                  unless: "pointingSelectedGlob",
                  "do": "setPointingToSelectedGlobs"
                }],
                POINTED_GLOB: ["setPointingId", {
                  "if": "pointingSelectedGlob",
                  then: {
                    "if": "hasShift",
                    then: {
                      "do": "pullPointingFromSelectedGlobs",
                      to: "notPointing"
                    },
                    "else": {
                      "if": "hasMeta",
                      to: "splittingGlob",
                      "else": {
                        to: "pointingSelectedGlob"
                      }
                    }
                  },
                  "else": {
                    "if": "hasShift",
                    then: {
                      "do": "pushPointingToSelectedGlobs",
                      to: "pointingSelectedGlob"
                    },
                    "else": {
                      "do": "setPointingToSelectedGlobs",
                      to: "pointingSelectedGlob"
                    }
                  }
                }],
                POINTED_BOUNDS: {
                  "if": "hasMeta",
                  to: "brushSelecting",
                  "else": {
                    unless: "isReadOnly",
                    to: "draggingSelection"
                  }
                },
                POINTED_CANVAS: [{
                  unless: "hasShift",
                  "do": "clearSelection"
                }, {
                  to: "brushSelecting"
                }],
                POINTED_ANCHOR: {
                  unless: "isReadOnly",
                  "do": "setPointingId",
                  to: "pointingAnchor"
                },
                POINTED_HANDLE: {
                  unless: "isReadOnly",
                  "do": "setPointingId",
                  to: "pointingHandle"
                },
                POINTED_BOUNDS_EDGE: {
                  unless: "isReadOnly",
                  "do": "beginEdgeTransform",
                  to: "transforming"
                },
                POINTED_BOUNDS_CORNER: {
                  unless: "isReadOnly",
                  "do": "beginCornerTransform",
                  to: "transforming"
                },
                POINTED_ROTATE_CORNER: {
                  unless: "isReadOnly",
                  to: "rotating"
                },
                // Panel
                STARTED_translating: {
                  unless: "isReadOnly",
                  to: "translating"
                },
                SET_NODES_X: "setPointingToSelectedNodessPointX",
                SET_NODES_Y: "setPointingToSelectedNodessPointY",
                SET_NODES_RADIUS: "setPointingToSelectedNodessRadius",
                SET_NODES_CAP: "setPointingToSelectedNodessCap",
                SET_NODES_LOCKED: "setPointingToSelectedNodessLocked",
                TOGGLED_LOCKED: "toggleSelectedNodesLocked",
                TOGGLED_NODE_LOCKED: "toggleSelectedNodesLocked",
                SET_GLOB_OPTIONS: "setSelectedGlobOptions",
                CHANGED_BOUNDS_X: "changeBoundsX",
                CHANGED_BOUNDS_Y: "changeBoundsY",
                CHANGED_BOUNDS_WIDTH: "changeBoundsWidth",
                CHANGED_BOUNDS_HEIGHT: "changeBoundsHeight",
                GENERATED_ITEMS: "setCanvasItems",
                // Navigator
                MOVED_NODE_ORDER: "moveNodeOrder",
                MOVED_GLOB_ORDER: "moveGlobOrder",
                // Toolbar
                STARTED_CREATING_NODES: {
                  to: "creatingNodes"
                },
                STARTED_GLOBBING_NODES: {
                  "if": "hasSelectedNodes",
                  to: "globbingNodes",
                  "else": {
                    to: "creatingNodes"
                  }
                }
              }
            },
            pointingSelectedGlob: {
              on: {
                STOPPED_POINTING: {
                  to: "notPointing"
                },
                MOVED_POINTER: {
                  unless: "isReadOnly",
                  "if": "distanceImpliesDrag",
                  to: "draggingSelection"
                }
              }
            },
            pointingSelectedNode: {
              on: {
                STOPPED_POINTING: {
                  to: "notPointing"
                },
                PRESSED_META: {
                  to: "resizingNodes"
                },
                MOVED_POINTER: {
                  unless: "isReadOnly",
                  "if": "distanceImpliesDrag",
                  to: "draggingSelection"
                }
              }
            },
            draggingSelection: {
              onEnter: "beginMove",
              onExit: "clearSnaps",
              on: {
                CANCELLED: {
                  "do": "cancelMove",
                  to: "notPointing"
                },
                WHEELED: "updateMove",
                PRESSED_OPTION: "updateMove",
                RELEASED_OPTION: "updateMove",
                MOVED_POINTER: "updateMove",
                STOPPED_POINTING: {
                  "do": "completeMove",
                  to: "notPointing"
                }
              }
            },
            resizingNodes: {
              onEnter: "beginRadiusMove",
              on: {
                PRESSED_SHIFT: "updateRadiusMove",
                RELEASED_SHIFT: "updateRadiusMove",
                PRESSED_OPTION: "updateRadiusMove",
                RELEASED_OPTION: "updateRadiusMove",
                RELEASED_META: {
                  "do": "completeRadiusMove",
                  to: "pointingSelectedNode"
                },
                MOVED_POINTER: "updateRadiusMove",
                STOPPED_POINTING: {
                  "do": "completeRadiusMove",
                  to: "notPointing"
                }
              }
            },
            pointingHandle: {
              onEnter: ["beginHandleMove", "setPointingToSelectedGlobs", "setSelectedHandle"],
              onExit: ["clearSnaps", "clearSelectedHandle"],
              on: {
                WHEELED: "updateHandleMove",
                MOVED_POINTER: "updateHandleMove",
                CANCELLED: {
                  "do": "cancelHandleMove",
                  to: "notPointing"
                },
                STOPPED_POINTING: {
                  "do": "completeHandleMove",
                  to: "notPointing"
                }
              }
            },
            pointingAnchor: {
              onEnter: ["beginAnchorMove", "setPointingToSelectedGlobs"],
              onExit: "clearSnaps",
              on: {
                WHEELED: "updateAnchorMove",
                MOVED_POINTER: "updateAnchorMove",
                CANCELLED: {
                  "do": "cancelAnchorMove",
                  to: "notPointing"
                },
                STOPPED_POINTING: {
                  "do": "completeAnchorMove",
                  to: "notPointing"
                }
              }
            },
            brushSelecting: {
              onEnter: ["startBrush", "clearHovers"],
              on: {
                HOVERED_GLOB: "pushHoveredGlob",
                UNHOVERED_GLOB: "pullHoveredGlob",
                HOVERED_NODE: "pushHoveredNode",
                UNHOVERED_NODE: "pullHoveredNode",
                MOVED_POINTER: "updateBrush",
                WHEELED: "updateBrush",
                STOPPED_POINTING: {
                  "do": "completeBrush",
                  to: "notPointing"
                },
                POINTED_CANVAS: {
                  "do": "cancelBrush",
                  to: "notPointing"
                },
                CANCELLED: {
                  "do": "cancelBrush",
                  to: "notPointing"
                }
              }
            },
            transforming: {
              on: {
                MOVED_POINTER: "updateTransform",
                WHEELED: "updateTransform",
                STOPPED_POINTING: {
                  "do": ["completeTransform"],
                  to: "notPointing"
                },
                CANCELLED: {
                  "do": "cancelTransform",
                  to: "notPointing"
                }
              }
            },
            rotating: {
              onEnter: "beginRotate",
              on: {
                MOVED_POINTER: "updateRotate",
                WHEELED: "updateRotate",
                STOPPED_POINTING: {
                  "do": "completeRotate",
                  to: "notPointing"
                },
                CANCELLED: {
                  "do": "cancelRotate",
                  to: "notPointing"
                }
              }
            },
            translating: {
              onEnter: "beginTranslation",
              on: {
                MOVED_POINTER_IN_TRANSLATE: {
                  "do": "updateTranslation"
                },
                STOPPED_POINTING: {
                  "do": "completeTranslation",
                  to: "notPointing"
                }
              }
            },
            panningWithThumbstick: {
              on: {
                MOVED_THUMBSTICK: "panCamera",
                STOPPED_MOVING_THUMBSTICK: {
                  to: "notPointing"
                }
              }
            },
            draggingWithThumbstick: {
              onEnter: "beginMove",
              on: {
                MOVED_THUMBSTICK: "updateMove",
                STOPPED_MOVING_THUMBSTICK: {
                  "do": "completeMove",
                  to: "notPointing"
                }
              }
            }
          }
        },
        creatingNodes: {
          on: {
            CANCELLED: {
              to: "selecting"
            },
            STARTED_CREATING_NODES: {
              to: "selecting"
            },
            STARTED_GLOBBING_NODES: {
              "if": "hasSelectedNodes",
              to: "globbingNodes"
            },
            POINTED_CANVAS: {
              "do": ["createNode"],
              to: "selecting"
            }
          }
        },
        globbingNodes: {
          on: {
            CANCELLED: {
              to: "selecting"
            },
            STARTED_GLOBBING_NODES: {
              to: "selecting"
            },
            STARTED_CREATING_NODES: {
              to: "creatingNodes"
            },
            HOVERED_NODE: {
              "do": "pushHoveredNode"
            },
            UNHOVERED_NODE: {
              "do": "pullHoveredNode"
            },
            POINTED_NODE: {
              "do": "createGlobBetweenNodes",
              to: "selecting"
            },
            POINTED_CANVAS: {
              "do": "createGlobToNewNode",
              to: "selecting"
            }
          }
        },
        splittingGlob: {
          on: {
            CANCELLED: {
              to: "selecting"
            },
            RELEASED_META: {
              to: "selecting"
            },
            SPLIT_GLOB: {
              "do": ["splitGlob", "clearSelection"],
              to: "selecting"
            },
            POINTED_CANVAS: {
              unless: "hasMeta",
              to: "selecting"
            }
          }
        }
      }
    },
    shareLinkModal: {
      on: {
        CHANGED_SHARE_LINKS: {
          "do": "setShareLinks"
        },
        CLOSED_SHARE_LINK_MODAL: {
          to: "ready"
        }
      }
    }
  },
  conditions: {
    isReadOnly: function isReadOnly(data) {
      return data.readOnly;
    },
    distanceImpliesDrag: function distanceImpliesDrag() {
      return vec.dist(inputs.pointer.origin, inputs.pointer.point) > 3;
    },
    hasSelection: function hasSelection(data) {
      var selectedNodes = data.selectedNodes,
          selectedGlobs = data.selectedGlobs;
      return selectedGlobs.length > 0 || selectedNodes.length > 0;
    },
    hasBoundsSelection: function hasBoundsSelection(data) {
      var selectedNodes = data.selectedNodes,
          selectedGlobs = data.selectedGlobs;
      return selectedGlobs.length + selectedNodes.length > 1;
    },
    pointingSelectedNode: function pointingSelectedNode(data) {
      return data.selectedNodes.includes(data.pointingId);
    },
    pointingSelectedGlob: function pointingSelectedGlob(data) {
      return data.selectedGlobs.includes(data.pointingId);
    },
    hasSelectedNodes: function hasSelectedNodes(data) {
      return data.selectedNodes.length > 0;
    },
    isTrackpadZoom: function isTrackpadZoom(_, payload) {
      return payload.optionKey || payload.ctrlKey;
    },
    hasMiddleButton: function hasMiddleButton(_, payload) {
      return payload.isPan;
    },
    hasMeta: function hasMeta(_, payload) {
      return payload.metaKey;
    },
    hasShift: function hasShift(_, payload) {
      return payload.shiftKey;
    },
    isShareLink: function isShareLink(_, payload) {
      return payload.isShareLink;
    }
  },
  actions: {
    // Code panel
    setCanvasItems: function setCanvasItems(data, payload) {
      commands.setCanvasItems(data, payload);
    },
    // Clipboard
    copySvgToClipboard: function copySvgToClipboard(data) {
      exports.copyToSvg(data, elms);
    },
    copyToClipboard: function copyToClipboard(data) {
      clipboard.copy(data);
    },
    startPasteFromClipboard: function startPasteFromClipboard() {
      clipboard.startPaste();
    },
    finishPasteFromClipboard: function finishPasteFromClipboard(data, copied) {
      clipboard.finishPaste(data, copied);
    },
    // Readonly
    setReadonly: function setReadonly(data) {
      data.readOnly = true;
    },
    clearReadonly: function clearReadonly(data) {
      data.readOnly = false;
    },
    // History
    enableHistory: function enableHistory() {
      history.enable();
    },
    disableHistory: function disableHistory() {
      history.disable();
    },
    undo: function undo(data) {
      history.undo(data);
    },
    redo: function redo(data) {
      history.redo(data);
    },
    // Elements (Maybe remove?)
    mountElement: function mountElement(data, payload) {
      elms[payload.id] = payload.elm;
    },
    deleteElement: function deleteElement(data, payload) {
      delete elms[payload.id];
    },
    // Display
    toggleTheme: function toggleTheme(data) {
      data.preferences.theme = data.preferences.theme === "dark" ? "light" : "dark";
      history.save(data);
    },
    loadTheme: function loadTheme(data) {
      document.body.classList.remove(data.preferences.theme === "dark" ? "light" : dark);
      document.body.classList.add(data.preferences.theme === "dark" ? dark : "light");
    },
    toggleFill: function toggleFill(data) {
      data.fill = !data.fill;
    },
    updateMvPointer: function updateMvPointer(data) {
      mvPointer.screen.set(inputs.pointer.point);
      mvPointer.world.set(screenToWorld(inputs.pointer.point, data.camera));
    },
    panCamera: function panCamera(data) {
      var camera = data.camera,
          document = data.document;
      var delta = vec.div(inputs.pointer.delta, camera.zoom);
      camera.point = vec.round(vec.add(camera.point, vec.neg(delta)));
      document.point = camera.point;
    },
    wheelPanCamera: function wheelPanCamera(data, payload) {
      var camera = data.camera,
          document = data.document;
      var delta = vec.div(vec.neg(payload.delta), camera.zoom);
      camera.point = vec.round(vec.sub(camera.point, delta));
      inputs.pointer.delta = vec.mul(vec.neg(delta), camera.zoom);
      document.point = camera.point;
    },
    zoomCamera: function zoomCamera(data, payload) {
      var camera = data.camera,
          viewport = data.viewport,
          document = data.document;
      var point = inputs.pointer.point;
      var delta = vec.mul(vec.neg(payload.delta), 5)[payload.shiftKey ? 0 : 1] / 500 * Math.max(0.1, camera.zoom);
      var pt0 = vec.add(vec.div(point, camera.zoom), camera.point);
      camera.zoom = Math.round(Math.max(Math.min(camera.zoom + delta, 10), 0.1) * 100) / 100;
      var pt1 = vec.add(vec.div(point, camera.zoom), camera.point);
      camera.point = vec.round(vec.sub(camera.point, vec.sub(pt1, pt0)));
      document.size = vec.round(vec.div(viewport.size, camera.zoom));
      document.point = camera.point;
    },
    setViewport: function setViewport(data, payload) {
      var viewport = data.viewport,
          camera = data.camera,
          document = data.document;
      var c0 = screenToWorld(vec.add(document.point, vec.div(viewport.size, 2)), camera);
      viewport.size = payload.size;
      document.size = vec.round(vec.div(viewport.size, camera.zoom));
      var c1 = screenToWorld(vec.add(document.point, vec.div(viewport.size, 2)), camera);
      document.point = vec.sub(document.point, vec.sub(c1, c0));
      camera.point = document.point;
    },
    zoomCameraToFitContent: function zoomCameraToFitContent(data) {
      var camera = data.camera,
          document = data.document,
          viewport = data.viewport;
      var bounds = getAllSelectedBoundingBox(data);

      if (!bounds) {
        // Canvas is empty
        data.camera.zoom = 1;
        data.camera.point = vec.neg(vec.div(viewport.size, 2));
        return;
      } // Find the new camera scale


      var s0 = (viewport.size[0] > 720 ? viewport.size[0] - 528 : viewport.size[0]) / bounds.width;
      var s1 = (viewport.size[1] - 128) / bounds.height;
      var smallerZoom = Math.min(s0, s1);
      camera.zoom = smallerZoom ? Math.max(0.1, Math.min(smallerZoom, 1)) : 1; // Center on the bounds

      document.size = vec.round(vec.div(viewport.size, camera.zoom));
      camera.point = vec.sub([bounds.minX + bounds.width / 2, bounds.minY + bounds.height / 2], vec.div(document.size, 2));
      document.point = camera.point;
    },
    // Selection
    deleteSelection: function deleteSelection(data) {
      commands.deleteSelection(data);
    },
    selectAll: function selectAll(data) {
      data.selectedGlobs = _toConsumableArray(data.globIds);
      data.selectedNodes = _toConsumableArray(data.nodeIds);
    },
    // Brush selection
    startBrush: function startBrush(data) {
      brushSession = new BrushSession(data);
    },
    updateBrush: function updateBrush(data) {
      brushSession.update(data);
    },
    cancelBrush: function cancelBrush(data) {
      brushSession.cancel(data);
      brushSession = undefined;
    },
    completeBrush: function completeBrush(data) {
      brushSession.complete(data);
      brushSession = undefined;
    },
    // Pointing Id
    setPointingId: function setPointingId(data, payload) {
      data.pointingId = payload.id;
    },
    clearPointingId: function clearPointingId(data) {
      data.pointingId = undefined;
    },
    clearSelection: function clearSelection(data) {
      data.bounds = undefined;
      data.selectedHandle = undefined;
      data.selectedNodes = [];
      data.selectedGlobs = [];
      data.highlightNodes = [];
      data.highlightGlobs = [];
      data.pointingId = undefined;
    },
    setPointingToSelectedNodes: function setPointingToSelectedNodes(data) {
      data.bounds = undefined;
      data.selectedHandle = undefined;
      data.selectedGlobs = [];
      data.selectedNodes = [data.pointingId];
    },
    pushPointingToSelectedNodes: function pushPointingToSelectedNodes(data) {
      data.selectedNodes.push(data.pointingId);
    },
    pullPointingFromSelectedNodes: function pullPointingFromSelectedNodes(data) {
      data.selectedNodes = data.selectedNodes.filter(function (id) {
        return id !== data.pointingId;
      });
    },
    setPointingToSelectedGlobs: function setPointingToSelectedGlobs(data) {
      data.bounds = undefined;
      data.selectedHandle = undefined;
      data.selectedNodes = [];
      data.selectedGlobs = [data.pointingId];
    },
    pushPointingToSelectedGlobs: function pushPointingToSelectedGlobs(data, payload) {
      data.selectedGlobs.push(payload.id);
      data.selectedHandle = undefined;
      data.selectedNodes = [];
    },
    pullPointingFromSelectedGlobs: function pullPointingFromSelectedGlobs(data) {
      data.selectedGlobs = data.selectedGlobs.filter(function (id) {
        return id !== data.pointingId;
      });
    },
    // Highlights
    pushHighlightNode: function pushHighlightNode(data, payload) {
      if (data.highlightNodes.includes(payload.id)) return;
      data.highlightNodes.push(payload.id);
    },
    pullHighlightNode: function pullHighlightNode(data, payload) {
      var index = data.highlightNodes.indexOf(payload.id);
      data.highlightNodes.splice(index, 1);
    },
    pushHoveredNode: function pushHoveredNode(data, payload) {
      if (data.hoveredNodes.includes(payload.id)) return;
      data.hoveredNodes.push(payload.id);
    },
    pullHoveredNode: function pullHoveredNode(data, payload) {
      var index = data.hoveredNodes.indexOf(payload.id);
      data.hoveredNodes.splice(index, 1);
    },
    pushHighlightGlob: function pushHighlightGlob(data, payload) {
      if (data.highlightGlobs.includes(payload.id)) return;
      data.highlightGlobs.push(payload.id);
    },
    pullHighlightGlob: function pullHighlightGlob(data, payload) {
      var index = data.highlightGlobs.indexOf(payload.id);
      data.highlightGlobs.splice(index, 1);
    },
    pushHoveredGlob: function pushHoveredGlob(data, payload) {
      if (data.hoveredGlobs.includes(payload.id)) return;
      data.hoveredGlobs.push(payload.id);
    },
    pullHoveredGlob: function pullHoveredGlob(data, payload) {
      var index = data.hoveredGlobs.indexOf(payload.id);
      data.hoveredGlobs.splice(index, 1);
    },
    clearHovers: function clearHovers(data) {
      data.hoveredGlobs = [];
      data.hoveredNodes = [];
    },
    // Nodes
    createNode: function createNode(data) {
      commands.createNode(data);
    },
    toggleSelectedNodesLocked: function toggleSelectedNodesLocked(data) {
      commands.toggleSelectionLocked(data);
    },
    toggleSelectedNodesCap: function toggleSelectedNodesCap(data, payload) {
      commands.toggleSelectedNodesCap(data, payload.id);
    },
    setPointingToSelectedNodessPointX: function setPointingToSelectedNodessPointX(data, payload) {
      commands.setPropertyOnSelectedNodes(data, {
        x: payload.value
      });
    },
    setPointingToSelectedNodessPointY: function setPointingToSelectedNodessPointY(data, payload) {
      commands.setPropertyOnSelectedNodes(data, {
        y: payload.value
      });
    },
    setPointingToSelectedNodessRadius: function setPointingToSelectedNodessRadius(data, payload) {
      commands.setPropertyOnSelectedNodes(data, {
        r: payload.value
      });
    },
    setPointingToSelectedNodessCap: function setPointingToSelectedNodessCap(data, payload) {
      commands.setPropertyOnSelectedNodes(data, {
        cap: payload.value
      });
    },
    setPointingToSelectedNodessLocked: function setPointingToSelectedNodessLocked(data, payload) {
      commands.setPropertyOnSelectedNodes(data, {
        locked: payload.value
      });
    },
    moveNodeOrder: function moveNodeOrder(data, payload) {
      commands.reorderNodes(data, payload.from, payload.to);
    },
    beginRadiusMove: function beginRadiusMove(data) {
      resizeSession = new ResizeSession(data, data.selectedNodes[0]);
    },
    updateRadiusMove: function updateRadiusMove(data) {
      resizeSession.update(data);
    },
    cancelRadiusMove: function cancelRadiusMove(data) {
      resizeSession.cancel(data);
      resizeSession = undefined;
    },
    completeRadiusMove: function completeRadiusMove(data) {
      resizeSession.complete(data);
      resizeSession = undefined;
    },
    // Globs
    setSelectedGlobOptions: function setSelectedGlobOptions(data, payload) {
      commands.updateGlobOptions(data, payload);
    },
    createGlobToNewNode: function createGlobToNewNode(data) {
      commands.createGlobToNewNode(data, inputs.pointer.point);
    },
    createGlobBetweenNodes: function createGlobBetweenNodes(data, payload) {
      commands.createGlobBetweenNodes(data, payload.id);
    },
    moveGlobOrder: function moveGlobOrder(data, payload) {
      commands.reorderGlobs(data, payload.from, payload.to);
    },
    splitGlob: function splitGlob(data, payload) {
      commands.splitGlob(data, payload.id);
    },
    // Handles
    setSelectedHandle: function setSelectedHandle(data, payload) {
      data.selectedHandle = payload;
    },
    clearSelectedHandle: function clearSelectedHandle(data) {
      data.selectedHandle = undefined;
    },
    beginHandleMove: function beginHandleMove(data, payload) {
      data.selectedHandle = payload;
      handleSession = new HandleSession(data, payload.id, payload.handle);
    },
    updateHandleMove: function updateHandleMove(data) {
      handleSession.update(data);
    },
    cancelHandleMove: function cancelHandleMove(data) {
      handleSession.cancel(data);
      handleSession = undefined;
    },
    completeHandleMove: function completeHandleMove(data) {
      handleSession.complete(data);
      handleSession = undefined;
    },
    // Anchors
    beginAnchorMove: function beginAnchorMove(data, payload) {
      anchorSession = new AnchorSession(data, payload.id, payload.anchor);
    },
    updateAnchorMove: function updateAnchorMove(data) {
      anchorSession.update(data);
    },
    cancelAnchorMove: function cancelAnchorMove(data) {
      anchorSession.cancel(data);
      anchorSession = undefined;
    },
    completeAnchorMove: function completeAnchorMove(data) {
      anchorSession.complete(data);
      anchorSession = undefined;
    },
    // Moving Stuff
    beginMove: function beginMove(data) {
      moveSession = new MoveSession(data);
    },
    updateMove: function updateMove(data) {
      moveSession.update(data);
    },
    cancelMove: function cancelMove(data) {
      moveSession.cancel(data);
      moveSession = undefined;
    },
    completeMove: function completeMove(data) {
      moveSession.complete(data);
      moveSession = undefined;
    },
    clearSnaps: function clearSnaps(data) {
      data.snaps.active = [];
    },
    nudgeUp: function nudgeUp(data, payload) {
      var _data$preferences = data.preferences,
          nudgeDistanceLarge = _data$preferences.nudgeDistanceLarge,
          nudgeDistanceSmall = _data$preferences.nudgeDistanceSmall;
      var dist = payload.shiftKey ? nudgeDistanceLarge : nudgeDistanceSmall;
      commands.moveBounds(data, [0, -dist]);
    },
    nudgeRight: function nudgeRight(data, payload) {
      var _data$preferences2 = data.preferences,
          nudgeDistanceLarge = _data$preferences2.nudgeDistanceLarge,
          nudgeDistanceSmall = _data$preferences2.nudgeDistanceSmall;
      var dist = payload.shiftKey ? nudgeDistanceLarge : nudgeDistanceSmall;
      commands.moveBounds(data, [dist, 0]);
    },
    nudgeDown: function nudgeDown(data, payload) {
      var _data$preferences3 = data.preferences,
          nudgeDistanceLarge = _data$preferences3.nudgeDistanceLarge,
          nudgeDistanceSmall = _data$preferences3.nudgeDistanceSmall;
      var dist = payload.shiftKey ? nudgeDistanceLarge : nudgeDistanceSmall;
      commands.moveBounds(data, [0, dist]);
    },
    nudgeLeft: function nudgeLeft(data, payload) {
      var _data$preferences4 = data.preferences,
          nudgeDistanceLarge = _data$preferences4.nudgeDistanceLarge,
          nudgeDistanceSmall = _data$preferences4.nudgeDistanceSmall;
      var dist = payload.shiftKey ? nudgeDistanceLarge : nudgeDistanceSmall;
      commands.moveBounds(data, [-dist, 0]);
    },
    // Translations (from the panel)
    beginTranslation: function beginTranslation(data, payload) {
      translateSession = new TranslateSession(data, payload);
    },
    updateTranslation: function updateTranslation(data) {
      translateSession.update(data);
    },
    cancelTranslation: function cancelTranslation(data) {
      translateSession.cancel(data);
      translateSession = undefined;
    },
    completeTranslation: function completeTranslation(data) {
      translateSession.complete(data);
      translateSession = undefined;
    },
    // Moving bounds (from the panel)
    changeBoundsX: function changeBoundsX(data, payload) {
      commands.moveBounds(data, [payload.value, 0]);
    },
    changeBoundsY: function changeBoundsY(data, payload) {
      commands.moveBounds(data, [0, payload.value]);
    },
    changeBoundsWidth: function changeBoundsWidth(data, payload) {
      commands.resizeBounds(data, [payload.value, 0]);
    },
    changeBoundsHeight: function changeBoundsHeight(data, payload) {
      commands.resizeBounds(data, [0, payload.value]);
    },
    // Edge or corner transforms
    beginEdgeTransform: function beginEdgeTransform(data, payload) {
      transformSession = new TransformSession(data, "edge", payload.edge);
    },
    beginCornerTransform: function beginCornerTransform(data, payload) {
      transformSession = new TransformSession(data, "corner", payload.corner);
    },
    updateTransform: function updateTransform(data) {
      transformSession.update(data);
    },
    cancelTransform: function cancelTransform(data) {
      transformSession.cancel(data);
      transformSession = undefined;
    },
    completeTransform: function completeTransform(data) {
      transformSession.complete(data);
      transformSession = undefined;
    },
    // Rotations
    beginRotate: function beginRotate(data) {
      rotateSession = new RotateSession(data);
    },
    updateRotate: function updateRotate(data) {
      rotateSession.update(data);
    },
    cancelRotate: function cancelRotate(data) {
      rotateSession.cancel(data);
      rotateSession = undefined;
    },
    completeRotate: function completeRotate(data) {
      rotateSession.complete(data);
      rotateSession = undefined;
    },
    // Code Panel
    setCode: function setCode(data, payload) {
      data.code[payload.fileId].code = payload.code;
      history.save(data);
    },
    openCodePanel: function openCodePanel(data) {
      data.codePanel.isOpen = true;
      history.save(data);
    },
    closeCodePanel: function closeCodePanel(data) {
      data.codePanel.isOpen = false;
      history.save(data);
    },
    increaseCodeFontSize: function increaseCodeFontSize(data) {
      data.codePanel.fontSize++;
      history.save(data);
    },
    decreaseCodeFontSize: function decreaseCodeFontSize(data) {
      data.codePanel.fontSize--;
      history.save(data);
    },
    // SHARE LINKS
    setShareLinks: function setShareLinks(data, payload) {
      data.shareUrls = payload.uuids;
      history.save(data);
    },
    mergeSharedLinkToLocal: function mergeSharedLinkToLocal(data) {
      if (false) return;
      if (typeof localStorage === "undefined") return;
      var saved = localStorage.getItem("glob_aldata_v6");

      var currentDoc = _objectSpread({}, current(data));

      currentDoc.shareUrls = [];

      if (saved) {
        (function () {
          var localDoc = migrate(JSON.parse(saved));
          var localBounds = getAllSelectedBoundingBox(localDoc);
          var currentBounds = getAllSelectedBoundingBox(currentDoc); // Center selection on new canvas

          var delta = [localBounds.minX - currentBounds.minX, localBounds.maxX + 200 - currentBounds.minY]; // Move the incoming items out of the way.

          var selectedNodes = currentDoc.selectedNodes,
              nodes = currentDoc.nodes,
              selectedGlobs = currentDoc.selectedGlobs,
              globs = currentDoc.globs;

          var _iterator = _createForOfIteratorHelper(selectedNodes),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _nodeId = _step.value;
              nodes[_nodeId].point = vec.round(vec.add(nodes[_nodeId].point, delta));
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          var _iterator2 = _createForOfIteratorHelper(selectedGlobs),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _globId = _step2.value;
              globs[_globId].D = vec.round(vec.add(globs[_globId].D, delta));
              globs[_globId].Dp = vec.round(vec.add(globs[_globId].Dp, delta));
            } // Merge data into the local document. If items with the same
            // id exist in the local document, we create new ids for the
            // incoming items. Make sure to update references, too (e.g the
            // glob.nodes array).

          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }

          var id;
          var nodeMap = {};

          for (var nodeId in currentDoc.nodes) {
            id = nodeId;
            nodeMap[nodeId] = nodeId;

            var node = _objectSpread({}, currentDoc.nodes[nodeId]);

            if (localDoc.nodes[id]) {
              id = uuid();
              node.id = id;
              nodeMap[nodeId] = id;
            }

            localDoc.nodes[id] = node;
          }

          for (var globId in currentDoc.globs) {
            id = globId;

            var glob = _objectSpread({}, currentDoc.globs[globId]);

            if (localDoc.globs[id]) {
              id = uuid();
              glob.id = id;
            }

            glob.nodes = glob.nodes.map(function (nodeId) {
              return nodeMap[nodeId];
            });
            localDoc.globs[id] = glob;
          }

          for (var groupId in currentDoc.groups) {
            id = groupId;

            var group = _objectSpread({}, currentDoc.groups[groupId]);

            if (localDoc.groups[id]) {
              id = uuid();
              group.id = id;
            }

            localDoc.groups[id] = group;
          }

          localDoc.nodeIds = Object.keys(localDoc.nodes);
          localDoc.globIds = Object.keys(localDoc.globs);
          localStorage.setItem("glob_aldata_v6", JSON.stringify(localDoc));
        })();
      } else {
        localStorage.setItem("glob_aldata_v6", JSON.stringify(currentDoc));
      }

      router.push("/");
    },
    // EVENTS
    loadLocalProject: function loadLocalProject(data) {
      data.readOnly = false;
      if (false) return;
      if (typeof localStorage === "undefined") return;
      var saved = localStorage.getItem("glob_aldata_v6");
      Object.assign(data, saved ? migrate(JSON.parse(saved)) : defaultData);
    },
    loadSharedProject: function loadSharedProject(data, payload) {
      data.name = payload.project.name;
      Object.assign(data, payload.project.document);
    },
    setEvents: function setEvents(data) {
      data.fill = false;
      data.selectedNodes = [];
      data.selectedGlobs = [];
      data.highlightGlobs = [];
      data.highlightNodes = [];
      data.hoveredNodes = [];
      data.hoveredGlobs = [];
      data.snaps.active = [];
      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);

      if (true) {
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        window.addEventListener("resize", handleResize);
        window.addEventListener("blur", handleWindowBlur);
        window.addEventListener("gesturestart", handleGestureStart);
        window.addEventListener("gesturechange", handleGestureEvent);
        window.addEventListener("gestureend", handleGestureEvent);
      }
    },
    teardown: function teardown(data) {
      if (!data.readOnly) {
        history.save(data);
      }

      if (true) {
        window.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("keyup", handleKeyUp);
        window.removeEventListener("resize", handleResize);
        window.removeEventListener("blur", handleWindowBlur);
        window.removeEventListener("gesturestart", handleGestureStart);
        window.removeEventListener("gesturechange", handleGestureEvent);
        window.removeEventListener("gestureend", handleGestureEvent);
      }
    },
    hardReset: function hardReset(data) {
      // Document
      data.id = Date.now().toString();
      data.name = "My Project";
      data.pages = {
        0: {
          id: "0",
          name: "Page 1",
          type: ICanvasItems.Page,
          locked: false,
          childIndex: 0
        }
      };
      data.code = {
        0: {
          id: "0",
          childIndex: 0,
          name: "My Code",
          code: ""
        }
      };
      data.groups = {};
      data.nodes = {};
      data.globs = {}; // State

      data.nodeIds = [];
      data.globIds = [];
      data.selectedGlobs = [];
      data.selectedNodes = [];
      data.highlightNodes = [];
      data.highlightGlobs = [];
      data.hoveredGlobs = [];
      data.hoveredNodes = [];
      data.snaps.active = [];
      data.selectedHandle = undefined;
      data.bounds = undefined;
      data.shareUrls = [];
      window.alert("Hard Reset!");
    }
  },
  values: {
    selectionBounds: function selectionBounds(data) {
      if (data.selectedGlobs.length + data.selectedNodes.length) {
        var bounds = getSelectedBoundingBox(data);
        return roundBounds(bounds);
      }

      return null;
    }
  }
});
/* -------------------- SESSIONS -------------------- */

var moveSession;
var handleSession;
var anchorSession;
var resizeSession;
var rotateSession;
var translateSession;
var transformSession;
var brushSession;
/* --------------------- INPUTS --------------------- */

export var isDarwin = function isDarwin() {
  return /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);
};
export var isWindows = function isWindows() {
  return /^Win/.test(window.navigator.platform);
};
export var mvPointer = {
  screen: motionValue([0, 0]),
  world: motionValue([0, 0])
};
/* ------------------ INPUT EVENTS ------------------ */

var prevScale = 1;

var handleGestureStart = function handleGestureStart(e) {
  prevScale = 1;
  e.preventDefault();
};

var handleGestureEvent = throttle(function (e) {
  var scale = e.scale;
  var delta = scale - prevScale;
  if (scale < 1) delta *= 2;
  prevScale = scale;
  state.send("WHEELED", {
    ctrlKey: true,
    delta: [0, delta * -50]
  });
}, 16, true);
var handleResize = throttle(function () {
  if (true) {
    state.send("RESIZED", {
      size: [window.innerWidth, window.innerHeight]
    });
  }
}, 16);

function handleWindowBlur() {
  inputs.handleWindowBlur();
}

function handleKeyDown(e) {
  var eventName = inputs.handleKeyDown(e.key, e.shiftKey, e.altKey, e.ctrlKey, e.metaKey);

  if (eventName) {
    state.send(eventName, {
      shiftKey: e.shiftKey,
      optionKey: e.altKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey || e.ctrlKey
    });
    e.preventDefault();
  }

  state.send("PRESSED_KEY", {
    key: e.key,
    shiftKey: e.shiftKey,
    optionKey: e.altKey,
    ctrlKey: e.ctrlKey,
    metaKey: e.metaKey || e.ctrlKey
  });

  if (isDarwin() ? e.metaKey : e.ctrlKey) {
    e.preventDefault();
  }
}

function handleKeyUp(e) {
  var eventName = inputs.handleKeyUp(e.key, e.shiftKey, e.altKey, e.ctrlKey, e.metaKey);

  if (eventName && eventName === "SAVED") {
    e.preventDefault();
  }

  if (eventName) {
    state.send(eventName, {
      shiftKey: e.shiftKey,
      optionKey: e.altKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey || e.ctrlKey
    });
    e.preventDefault();
  }

  state.send("RELEASED_KEY", {
    key: e.key,
    shiftKey: e.shiftKey,
    optionKey: e.altKey,
    ctrlKey: e.ctrlKey,
    metaKey: e.metaKey || e.ctrlKey
  });
}

export var useSelector = createSelectorHook(state);
export default state; // state.onUpdate((s) => console.log(s.active, s.log[0]))