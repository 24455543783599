import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import inputs from "lib/inputs";
import * as vec from "lib/vec";
import { pointInRect } from "lib/utils";
var MIN_SPEED = 5,
    // Min length of vector (in screen space) to start snapping
SNAP_DISTANCE = 4; // Min distance (in screen space) to make a snap

export default function getNodeSnapper(node, nodes) {
  // Since we'll be using a closure, create a not-proxy copy of the data.
  var iPoint = _toConsumableArray(node.point);

  var r = node.radius;
  var iNodes = nodes.filter(function (_ref) {
    var id = _ref.id;
    return id !== node.id;
  }).map(function (_ref2) {
    var id = _ref2.id,
        point = _ref2.point,
        radius = _ref2.radius;
    return {
      id: id,
      point: _toConsumableArray(point),
      radius: radius
    };
  }); // The delta here should be in world space!
  // let delta = vec.div(vec.vec(pointer.origin, pointer.point), camera.zoom)

  return function getSnap(delta, camera, document) {
    // Where would the point be without any snaps?
    var next = vec.add(iPoint, delta);
    var results = [];
    var d,
        // either [center] or [x-axis, y-axis]
    dx = SNAP_DISTANCE,
        // distance to test point (screen space)
    dy = SNAP_DISTANCE,
        sx = next[0],
        sy = next[1]; // Is the user skipping snaps? Is the user moving quickly? Return point.

    if (vec.len(inputs.pointer.delta) > MIN_SPEED) return {
      delta: vec.vec(iPoint, next),
      point: next,
      snaps: []
    }; // Get a rect 1.5x the size of the document.

    var minX = document.point[0] - document.size[0] * 0.25,
        minY = document.point[1] - document.size[1] * 0.25,
        maxX = minX + document.size[0] * 1.5,
        maxY = minY + document.size[1] * 1.5; // Get nodes that are close enough;

    var nodesToCheck = iNodes.filter(function (_ref3) {
      var point = _ref3.point;
      return pointInRect(point, minX, minY, maxX, maxY);
    }); // CENTER -> CENTER

    var _iterator = _createForOfIteratorHelper(nodesToCheck),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _n3 = _step.value;
        d = vec.dist(_n3.point, next) * camera.zoom;

        if (d < dx) {
          dx = d;
          sx = _n3.point[0];
          sy = _n3.point[1];
          results[0] = {
            id: _n3.id,
            from: _n3.point,
            to: [0, 0]
          };
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    if (!results[0]) {
      // If we haven't snapped to a center...
      // CENTER X -> CENTER X / CENTER Y -> CENTER Y
      var _iterator2 = _createForOfIteratorHelper(nodesToCheck),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _n2 = _step2.value;
          // Center x -> Center x
          d = Math.abs(next[0] - _n2.point[0]) * camera.zoom;

          if (d < dx) {
            dx = d;
            sx = _n2.point[0];
            results[0] = {
              id: _n2.id,
              from: _n2.point,
              to: [0, 0]
            };
          } // Center y -> Center y


          d = Math.abs(next[1] - _n2.point[1]) * camera.zoom;

          if (d < dy) {
            dy = d;
            sy = _n2.point[1];
            results[1] = {
              id: _n2.id,
              from: _n2.point,
              to: [0, 0]
            };
          }
        } // If we have at least one axis unsnapped...
        // CENTER X -> EDGES X, CENTER Y -> EDGES Y

      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      if (!(results[0] && results[1])) {
        var _iterator3 = _createForOfIteratorHelper(nodesToCheck),
            _step3;

        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var n = _step3.value;

            // If we don't have a center x -> center x snap, check center x -> edges x
            if (!results[0]) {
              // Center x -> min x
              d = Math.abs(next[0] - (n.point[0] - n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = n.point[0] - n.radius;
                results[0] = {
                  id: n.id,
                  from: vec.sub(n.point, [n.radius, 0]),
                  to: [0, 0]
                };
              } // Center x -> max x


              d = Math.abs(next[0] - (n.point[0] + n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = n.point[0] + n.radius;
                results[0] = {
                  id: n.id,
                  from: vec.add(n.point, [n.radius, 0]),
                  to: [0, 0]
                };
              }
            }

            if (!results[1]) {
              // Center y -> min y
              d = Math.abs(next[1] - (n.point[1] - n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = n.point[1] - n.radius;
                results[1] = {
                  id: n.id,
                  from: vec.sub(n.point, [0, n.radius]),
                  to: [0, 0]
                };
              } // Center y -> max y


              d = Math.abs(next[1] - (n.point[1] + n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = n.point[1] + n.radius;
                results[1] = {
                  id: n.id,
                  from: vec.add(n.point, [0, n.radius]),
                  to: [0, 0]
                };
              }
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      } // If we have still at least one axis unsnapped...
      // check from left and right edges.


      if (!(results[0] && results[1])) {
        var _iterator4 = _createForOfIteratorHelper(nodesToCheck),
            _step4;

        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var _n = _step4.value;

            // EDGES X -> EDGES X
            if (!results[0]) {
              // Min x -> center x
              d = Math.abs(next[0] - r - _n.point[0]) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] + r;
                results[0] = {
                  id: _n.id,
                  from: _n.point,
                  to: [-r, 0]
                };
              } // Min x -> min x


              d = Math.abs(next[0] - r - (_n.point[0] - _n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] - _n.radius + r;
                results[0] = {
                  id: _n.id,
                  from: vec.sub(_n.point, [_n.radius, 0]),
                  to: [-r, 0]
                };
              } // Min x -> max x


              d = Math.abs(next[0] - r - (_n.point[0] + _n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] + _n.radius + r;
                results[0] = {
                  id: _n.id,
                  from: vec.add(_n.point, [_n.radius, 0]),
                  to: [-r, 0]
                };
              } // Max x -> center x


              d = Math.abs(next[0] + r - _n.point[0]) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] - r;
                results[0] = {
                  id: _n.id,
                  from: _n.point,
                  to: [r, 0]
                };
              } // Max x -> min x


              d = Math.abs(next[0] + r - (_n.point[0] - _n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] - _n.radius - r;
                results[0] = {
                  id: _n.id,
                  from: vec.sub(_n.point, [_n.radius, 0]),
                  to: [r, 0]
                };
              } // Max x -> max x


              d = Math.abs(next[0] + r - (_n.point[0] + _n.radius)) * camera.zoom;

              if (d < dx) {
                dx = d;
                sx = _n.point[0] + _n.radius - r;
                results[0] = {
                  id: _n.id,
                  from: vec.add(_n.point, [_n.radius, 0]),
                  to: [r, 0]
                };
              }
            } // EDGES Y -> EDGES Y


            if (!results[1]) {
              // Min y -> center y
              d = Math.abs(next[1] - r - _n.point[1]) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] + r;
                results[1] = {
                  id: _n.id,
                  from: _n.point,
                  to: [0, -r]
                };
              } // Min y -> min y


              d = Math.abs(next[1] - r - (_n.point[1] - _n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] - _n.radius + r;
                results[1] = {
                  id: _n.id,
                  from: vec.sub(_n.point, [0, _n.radius]),
                  to: [0, -r]
                };
              } // Min y -> max y


              d = Math.abs(next[1] - r - (_n.point[1] + _n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] + _n.radius + r;
                results[1] = {
                  id: _n.id,
                  from: vec.add(_n.point, [0, _n.radius]),
                  to: [0, -r]
                };
              } // Max y -> center y


              d = Math.abs(next[1] + r - _n.point[1]) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] - r;
                results[1] = {
                  id: _n.id,
                  from: _n.point,
                  to: [0, r]
                };
              } // Max y -> min y


              d = Math.abs(next[1] + r - (_n.point[1] - _n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] - _n.radius - r;
                results[1] = {
                  id: _n.id,
                  from: vec.sub(_n.point, [0, _n.radius]),
                  to: [0, r]
                };
              } // Max y -> max y


              d = Math.abs(next[1] + r - (_n.point[1] + _n.radius)) * camera.zoom;

              if (d < dy) {
                dy = d;
                sy = _n.point[1] + _n.radius - r;
                results[1] = {
                  id: _n.id,
                  from: vec.add(_n.point, [0, _n.radius]),
                  to: [0, r]
                };
              }
            }
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }
      }
    }

    return {
      delta: vec.vec(iPoint, [sx, sy]),
      point: [sx, sy],
      snaps: results.map(function (r) {
        return _objectSpread(_objectSpread({}, r), {}, {
          to: vec.add(r.to, [sx, sy])
        });
      })
    };
  };
}