/**
 * The JSON schema for a shared project.
 */
export var ICanvasItems;

(function (ICanvasItems) {
  ICanvasItems[ICanvasItems["Page"] = 0] = "Page";
  ICanvasItems[ICanvasItems["Node"] = 1] = "Node";
  ICanvasItems[ICanvasItems["Glob"] = 2] = "Glob";
  ICanvasItems[ICanvasItems["Group"] = 3] = "Group";
  ICanvasItems[ICanvasItems["Point"] = 4] = "Point";
  ICanvasItems[ICanvasItems["Line"] = 5] = "Line";
})(ICanvasItems || (ICanvasItems = {}));

export var ISnapTypes;

(function (ISnapTypes) {
  ISnapTypes["NodesCenter"] = "NodesCenter";
  ISnapTypes["NodesX"] = "NodesX";
  ISnapTypes["NodesY"] = "NodesY";
  ISnapTypes["Handle"] = "Handle";
  ISnapTypes["HandleStraight"] = "HandleStraight";
})(ISnapTypes || (ISnapTypes = {}));