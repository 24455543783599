function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import exampleCode from "components/ui/code-panel/example-code";
import { ICanvasItems } from "types";
/**
 * Move the properties from "Glob.options" onto the glob object itself.
 * @param data
 */

function moveGlobOptionsOntoGlob(data) {
  var globIds = data.globIds,
      globs = data.globs;

  var _iterator = _createForOfIteratorHelper(globIds),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var globId = _step.value;
      var glob = globs[globId];

      if (glob.options !== undefined) {
        Object.assign(glob, glob.options);
        delete glob.options;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
/**
 * Create the document structure with page, parentIds, groups, and so on.
 * @param data
 */


function createDocument(data) {
  data.version = "2";
  data.pageId = "0";
  data.pages = {
    0: {
      id: "0",
      name: "Page 1",
      type: ICanvasItems.Page,
      locked: false,
      childIndex: 0
    }
  };
  data.code = {
    0: {
      id: "0",
      childIndex: 0,
      name: "My Code",
      code: exampleCode
    }
  };
  data.groups = {};
  var globIds = data.globIds,
      globs = data.globs,
      nodeIds = data.nodeIds,
      nodes = data.nodes;
  var i = 0;

  var _iterator2 = _createForOfIteratorHelper(nodeIds),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var nodeId = _step2.value;
      var node = nodes[nodeId];
      node.type = ICanvasItems.Node;
      node.parentId = "0";
      node.childIndex = i; // @ts-ignore

      delete node.zIndex;
      i++;
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }

  var _iterator3 = _createForOfIteratorHelper(globIds),
      _step3;

  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var globId = _step3.value;
      var glob = globs[globId];
      glob.type = ICanvasItems.Glob;
      glob.parentId = "0";
      glob.childIndex = i; // @ts-ignore

      delete glob.zIndex;
      i++;
    } // Get saved items from local storage and attempt to restore

  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }

  var saved;
  saved = localStorage.getItem("globs_editor_theme");

  if (saved !== null) {
    localStorage.removeItem("globs_editor_theme");
  } // Get code from local storage


  saved = localStorage.getItem("__globs_code");

  if (saved !== null) {
    try {
      var json = JSON.parse(saved);
      data.code["0"].code = json.code;
      data.codePanel.fontSize = json.style.fontSize;
    } catch (e) {
      console.warn("Could not parse code.");
    }

    localStorage.removeItem("__globs_code");
  }
}

function supportShareUrls(data) {
  if ("shareUrl" in data) {
    // @ts-ignore
    data.shareUrls = [data.shareUrl]; // @ts-ignore

    delete data.shareUrl;
  }
}

function addPreferences(data) {
  // @ts-ignore
  data.preferences = {
    // @ts-ignore
    theme: data.theme,
    nudgeDistanceSmall: 1,
    nudgeDistanceLarge: 10
  }; // @ts-ignore

  delete data.theme;
}

export default function migrate(data) {
  if (!("version" in data) || Number(data.version) < 1) {
    if (data.globIds.length > 0 && "options" in data.globs[data.globIds[0]]) {
      moveGlobOptionsOntoGlob(data);
    }

    data.version = "1";
  }

  if (Number(data.version) < 2) {
    createDocument(data);
    data.version = "2";
  }

  if (Number(data.version) < 3) {
    supportShareUrls(data);
    data.version = "3";
  }

  if (Number(data.version) < 4) {
    addPreferences(data);
    data.version = "4";
  }

  return data;
}