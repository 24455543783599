import _classCallCheck from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { motionValue } from "framer-motion";
import state from "lib/state";
import * as vec from "lib/vec";
export var isDarwin = function isDarwin() {
  return /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);
};
export var isWindows = function isWindows() {
  return /^Win/.test(window.navigator.platform);
};

var Inputs = function Inputs() {
  var _this = this;

  _classCallCheck(this, Inputs);

  _defineProperty(this, "keys", {});

  _defineProperty(this, "modifiers", {});

  _defineProperty(this, "pointer", {
    id: -1,
    type: "mouse",
    point: [0, 0],
    delta: [0, 0],
    origin: [0, 0],
    buttons: 0,
    axis: "any",
    points: new Set()
  });

  _defineProperty(this, "mvPointer", {
    screen: motionValue([0, 0]),
    world: motionValue([0, 0])
  });

  _defineProperty(this, "downCommands", {
    ArrowLeft: [{
      eventName: "NUDGED_LEFT",
      modifiers: ["shiftKey"]
    }, {
      eventName: "NUDGED_LEFT",
      modifiers: []
    }],
    ArrowRight: [{
      eventName: "NUDGED_RIGHT",
      modifiers: ["shiftKey"]
    }, {
      eventName: "NUDGED_RIGHT",
      modifiers: []
    }],
    ArrowDown: [{
      eventName: "NUDGED_DOWN",
      modifiers: ["shiftKey"]
    }, {
      eventName: "NUDGED_DOWN",
      modifiers: []
    }],
    ArrowUp: [{
      eventName: "NUDGED_UP",
      modifiers: ["shiftKey"]
    }, {
      eventName: "NUDGED_UP",
      modifiers: []
    }],
    z: [{
      eventName: "REDO",
      modifiers: ["metaKey", "shiftKey"]
    }, {
      eventName: "UNDO",
      modifiers: ["metaKey"]
    }],
    a: [{
      eventName: "SELECTED_ALL",
      modifiers: ["metaKey"]
    }],
    s: [{
      eventName: "SAVED",
      modifiers: ["metaKey"]
    }],
    c: [{
      eventName: "EXPORTED",
      modifiers: ["shiftKey", "metaKey"]
    }, {
      eventName: "COPIED",
      modifiers: ["metaKey"]
    }],
    o: [{
      eventName: "HARD_RESET",
      modifiers: ["shiftKey", "metaKey"]
    }],
    x: [{
      eventName: "CUT",
      modifiers: ["metaKey"]
    }],
    v: [{
      eventName: "PASTED",
      modifiers: ["metaKey"]
    }],
    g: [{
      eventName: "STARTED_GLOBBING_NODES",
      modifiers: []
    }],
    l: [{
      eventName: "TOGGLED_LOCKED",
      modifiers: []
    }],
    n: [{
      eventName: "STARTED_CREATING_NODES",
      modifiers: []
    }],
    Shift: [{
      eventName: "PRESSED_SHIFT",
      modifiers: []
    }],
    Option: [{
      eventName: "PRESSED_OPTION",
      modifiers: []
    }],
    Alt: [{
      eventName: "PRESSED_OPTION",
      modifiers: []
    }],
    Meta: [{
      eventName: "PRESSED_META",
      modifiers: []
    }],
    Escape: [{
      eventName: "CANCELLED",
      modifiers: []
    }],
    Enter: [{
      eventName: "CONFIRMED",
      modifiers: []
    }],
    Delete: [{
      eventName: "DELETED",
      modifiers: []
    }],
    Backspace: [{
      eventName: "DELETED",
      modifiers: []
    }],
    " ": [{
      eventName: "PRESSED_SPACE",
      modifiers: []
    }],
    "]": [{
      eventName: "MOVED_FORWARD",
      modifiers: ["metaKey"]
    }],
    "[": [{
      eventName: "MOVED_BACKWARD",
      modifiers: ["metaKey"]
    }],
    "‘": [{
      eventName: "MOVED_TO_FRONT",
      modifiers: ["metaKey", "shiftKey"]
    }],
    "“": [{
      eventName: "MOVED_TO_BACK",
      modifiers: ["metaKey", "shiftKey"]
    }]
  });

  _defineProperty(this, "upCommands", {
    " ": [{
      eventName: "RELEASED_SPACE",
      modifiers: []
    }],
    Shift: [{
      eventName: "RELEASED_SHIFT",
      modifiers: []
    }],
    Option: [{
      eventName: "RELEASED_OPTION",
      modifiers: []
    }],
    Alt: [{
      eventName: "PRESSED_OPTION",
      modifiers: []
    }],
    Meta: [{
      eventName: "RELEASED_META",
      modifiers: []
    }]
  });

  _defineProperty(this, "handlePointerDown", function () {
    var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "mouse";
    var buttons = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
    var shiftKey = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var altKey = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    var ctrlKey = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
    var metaKey = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : false;
    Object.assign(_this.pointer, {
      id: id,
      type: type,
      buttons: buttons,
      direction: "any",
      origin: [x, y],
      point: [x, y],
      delta: [0, 0]
    });
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);
  });

  _defineProperty(this, "handlePointerMove", function () {
    var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
    var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "mouse";
    var buttons = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
    var shiftKey = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var altKey = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    var ctrlKey = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
    var metaKey = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : false;
    var ox = Math.abs(x - _this.pointer.origin[0]);
    var oy = Math.abs(y - _this.pointer.origin[1]);
    Object.assign(_this.pointer, {
      id: id,
      type: type,
      buttons: buttons,
      direction: "any",
      point: [x, y],
      axis: ox > oy ? "x" : "y",
      delta: vec.sub([x, y], _this.pointer.point)
    });
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);
  });

  _defineProperty(this, "handlePointerUp", function () {
    var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "mouse";
    var buttons = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    var shiftKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var altKey = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var ctrlKey = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    var metaKey = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
    Object.assign(_this.pointer, {
      id: -1,
      type: type,
      buttons: buttons,
      direction: "any",
      point: [x, y],
      delta: vec.sub([x, y], _this.pointer.point)
    });
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);
  });

  _defineProperty(this, "handlePointerCancel", function () {
    var x = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "mouse";
    var buttons = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    var shiftKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var altKey = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var ctrlKey = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    var metaKey = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
    Object.assign(_this.pointer, {
      id: -1,
      type: type,
      buttons: buttons,
      direction: "any",
      point: [x, y],
      delta: vec.sub([x, y], _this.pointer.point)
    });
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);
  });

  _defineProperty(this, "handleKeyDown", function (key) {
    var shiftKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var altKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var ctrlKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var metaKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    if (key === "Control" && !isDarwin()) key = "Meta";
    if (_this.keys[key] && !["z", "c", "x", "v"].includes(key)) return;
    _this.keys[key] = true;
    if (shiftKey && !_this.modifiers.shiftKey) state.send("PRESSED_SHIFT");
    if (altKey && !_this.modifiers.altKey) state.send("PRESSED_OPTION");
    if (ctrlKey && !_this.modifiers.ctrlKey) state.send("PRESSED_CONTROL");
    if (metaKey && !_this.modifiers.metaKey) state.send("PRESSED_META");
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);

    if (key in _this.downCommands) {
      var _iterator = _createForOfIteratorHelper(_this.downCommands[key]),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _step.value,
              _modifiers = _step$value.modifiers,
              eventName = _step$value.eventName;

          if (_modifiers.every(function (command) {
            return _this.modifiers[command];
          })) {
            return eventName;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }

    return false;
  });

  _defineProperty(this, "handleKeyUp", function (key) {
    var shiftKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var altKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var ctrlKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var metaKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    if (key === "Control" && !isDarwin()) key = "Meta";
    _this.keys[key] = false;
    if (shiftKey && !_this.modifiers.shiftKey) state.send("RELEASED_SHIFT");
    if (altKey && !_this.modifiers.altKey) state.send("RELEASED_OPTION");
    if (ctrlKey && !_this.modifiers.ctrlKey) state.send("RELEASED_CONTROL");
    if (metaKey && !_this.modifiers.metaKey) state.send("RELEASED_META");
    var modifiers = {
      shiftKey: shiftKey,
      optionKey: altKey,
      ctrlKey: ctrlKey,
      metaKey: isDarwin() ? metaKey : ctrlKey
    };
    Object.assign(_this.modifiers, modifiers);

    if (key in _this.upCommands) {
      var _iterator2 = _createForOfIteratorHelper(_this.upCommands[key]),
          _step2;

      try {
        var _loop = function _loop() {
          var _step2$value = _step2.value,
              modifiers = _step2$value.modifiers,
              eventName = _step2$value.eventName;

          if (modifiers.every(function (command) {
            return modifiers[command];
          })) {
            return {
              v: eventName
            };
          }
        };

        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _ret = _loop();

          if (typeof _ret === "object") return _ret.v;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }

    return false;
  });

  _defineProperty(this, "handleWindowBlur", function () {
    _this.keys = {};
    _this.pointer.id = -1;
    _this.pointer.buttons = 0;

    _this.pointer.points.clear();
  });

  _defineProperty(this, "handleThumbstickMove", function (x, y) {
    _this.pointer.delta = [x, y];
    _this.pointer.point = vec.add(_this.pointer.point, [x, y]);
    var ox = Math.abs(_this.pointer[0] - _this.pointer.origin[0]);
    var oy = Math.abs(_this.pointer[1] - _this.pointer.origin[1]);
    _this.pointer.axis = ox > oy ? "x" : "y";
  });
};

export default new Inputs();