// Some helpers for drawing SVGs.
import * as vec from "./vec";
import { getSweep } from "utils";
import { getBoundsBetweenPoints } from "./bounds-utils";
// General
export function ellipse(A, r) {
  return "M ".concat(A[0] - r, ",").concat(A[1], "\n      a ").concat(r, ",").concat(r, " 0 1,0 ").concat(r * 2, ",0\n      a ").concat(r, ",").concat(r, " 0 1,0 -").concat(r * 2, ",0 ");
}
export function moveTo(v) {
  return "M ".concat(v[0], ",").concat(v[1], " ");
}
export function lineTo(v) {
  return "L ".concat(v[0], ",").concat(v[1], " ");
}
export function line(a) {
  for (var _len = arguments.length, pts = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    pts[_key - 1] = arguments[_key];
  }

  return moveTo(a) + pts.map(function (p) {
    return lineTo(p);
  }).join();
}
export function hLineTo(v) {
  return "H ".concat(v[0], ",").concat(v[1], " ");
}
export function vLineTo(v) {
  return "V ".concat(v[0], ",").concat(v[1], " ");
}
/**
 * Return the path for a rectangle between two points.
 * @param a
 * @param b
 * @returns
 */

export function rectFromBounds(bounds) {
  var minX = bounds.minX,
      maxX = bounds.maxX,
      minY = bounds.minY,
      maxY = bounds.maxY;
  return [moveTo([minX, maxX]), lineTo([maxX, minY]), lineTo([maxX, maxY]), lineTo([minX, minY]), closePath()].join(" ");
}
/**
 * Return the path for a rectangle with a given point and size.
 * @param point
 * @param size
 * @returns
 */

export function rect(point, size) {
  return rectFromBounds(getBoundsBetweenPoints(point, vec.add(point, size)));
}
export function bezierTo(A, B, C) {
  return "C ".concat(A[0], ",").concat(A[1], " ").concat(B[0], ",").concat(B[1], " ").concat(C[0], ",").concat(C[1], " ");
}
export function arcTo(C, r, A, B) {
  return [// moveTo(A),
  "A", r, r, 0, getSweep(C, A, B) > 0 ? "1" : "0", 0, B[0], B[1]].join(" ");
}
export function closePath() {
  return "Z";
}
export function rectTo(A) {
  return ["R", A[0], A[1]].join(" ");
}
export function getPointAtLength(path, length) {
  var point = path.getPointAtLength(length);
  return [point.x, point.y];
}