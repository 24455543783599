import _classCallCheck from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

var BaseSession = function BaseSession(data) {
  _classCallCheck(this, BaseSession);

  _defineProperty(this, "update", function (data) {// Update the state
  });

  _defineProperty(this, "cancel", function (data) {// Clean up the change
  });

  _defineProperty(this, "complete", function (data) {// Create a command
  });
};

export { BaseSession as default };