import state, { useSelector } from "lib/state";
export default function useTheme() {
  var theme = useSelector(function (state) {
    return state.data.preferences.theme;
  });

  function toggle() {
    state.send("TOGGLED_THEME");
  }

  return {
    theme: theme,
    toggle: toggle
  };
}