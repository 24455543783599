import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _toConsumableArray from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { current } from "immer";
import * as gtag from "lib/gtag";
import * as vec from "lib/vec";
import inputs from "lib/inputs";
import { getLineLineIntersection, getResizedBounds, getSelectedBoundingBox, getNewNode, getNewGlob, screenToWorld, updateGlobPoints, getSelectionSnapshot, getGlobPoints } from "lib/utils";
import { getClosestPointOnCurve, getNormalOnCurve } from "lib/bez";
import history, { Command, CommandType } from "lib/history";
import AnchorSession from "./sessions/AnchorSession";
import TransformSession from "./sessions/TransformSession";
import RotateSession from "./sessions/RotateSession";
import MoveSession from "./sessions/MoveSession";
/* -------------------- Commands -------------------- */

export function createNode(data) {
  var point = vec.round(screenToWorld(inputs.pointer.point, data.camera));
  var node = getNewNode(point);
  gtag.event({
    action: "create_node",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.CreateNode,
    "do": function _do(data) {
      data.nodeIds.push(node.id);
      data.nodes[node.id] = node;
      data.hoveredNodes = [node.id];
      data.selectedNodes = [node.id];
    },
    undo: function undo(data) {
      data.nodeIds = data.nodeIds.filter(function (id) {
        return id !== node.id;
      });
      delete data.nodes[node.id];
    }
  }));
}
export function createGlobToNewNode(data, point) {
  var _current = current(data),
      sNodes = _current.nodes,
      sSelectedNodes = _current.selectedNodes,
      sCamera = _current.camera;

  var newNode = getNewNode(screenToWorld(point, sCamera));
  newNode.radius = sSelectedNodes.reduce(function (a, c) {
    return sNodes[c].radius < a ? sNodes[c].radius : a;
  }, sNodes[sSelectedNodes[0]].radius);
  var newGlobs = sSelectedNodes.map(function (nodeId) {
    return getNewGlob(sNodes[nodeId], newNode);
  });
  gtag.event({
    action: "create_glob_to_new_node",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.CreateNode,
    "do": function _do(data) {
      data.nodeIds.push(newNode.id);
      data.nodes[newNode.id] = newNode;

      var _iterator = _createForOfIteratorHelper(newGlobs),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var glob = _step.value;
          data.globIds.push(glob.id);
          data.globs[glob.id] = glob;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      data.selectedGlobs = [];
      data.selectedNodes = [newNode.id];
    },
    undo: function undo(data) {
      delete data.nodes[newNode.id];
      data.nodeIds = Object.keys(data.nodes);

      var _iterator2 = _createForOfIteratorHelper(newGlobs),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var glob = _step2.value;
          delete data.globs[glob.id];
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      data.globIds = Object.keys(data.globs);
    }
  }));
}
export function createGlobBetweenNodes(data, targetId) {
  var _current2 = current(data),
      sGlobIds = _current2.globIds,
      sGlobs = _current2.globs,
      sNodes = _current2.nodes,
      sSelectedNodes = _current2.selectedNodes;

  var globs = sGlobIds.map(function (id) {
    return sGlobs[id];
  }); // Don't create a second glob between nodes, if one already exists

  var newGlobs = sSelectedNodes.filter(function (nodeId) {
    return !globs.find(function (_ref) {
      var nodes = _ref.nodes;
      return nodes.includes(nodeId) && nodes.includes(targetId);
    });
  }).map(function (nodeId) {
    return getNewGlob(sNodes[nodeId], sNodes[targetId]);
  });
  gtag.event({
    action: "create_glob_between_nodes",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.CreateGlob,
    "do": function _do(data) {
      var _iterator3 = _createForOfIteratorHelper(newGlobs),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var glob = _step3.value;
          data.globs[glob.id] = glob;
          data.globIds.push(glob.id);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      data.selectedGlobs = [];
      data.selectedNodes[targetId];
    },
    undo: function undo(data) {
      var _iterator4 = _createForOfIteratorHelper(newGlobs),
          _step4;

      try {
        for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
          var glob = _step4.value;
          delete data.globs[glob.id];
        }
      } catch (err) {
        _iterator4.e(err);
      } finally {
        _iterator4.f();
      }

      data.globIds = Object.keys(data.globs);
    }
  }));
}
export function pasteSelection(data, pasted) {
  gtag.event({
    action: "pasted_selection",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Paste,
    "do": function _do(data) {
      Object.assign(data.nodes, pasted.nodes);
      Object.assign(data.globs, pasted.globs);
      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
      data.selectedNodes = Object.keys(pasted.nodes);
      data.selectedGlobs = Object.keys(pasted.globs);
      var bounds = getSelectedBoundingBox(data);
      if (!bounds) return;
      var point = screenToWorld(vec.div(data.viewport.size, 2), data.camera);
      var delta = vec.sub(point, vec.add([bounds.minX, bounds.minY], vec.div([bounds.width, bounds.height], 2)));
      var globs = data.globs,
          nodes = data.nodes;

      var _iterator5 = _createForOfIteratorHelper(data.selectedGlobs),
          _step5;

      try {
        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
          var globId = _step5.value;
          var glob = globs[globId];
          var _globs$glob$id = globs[glob.id],
              D = _globs$glob$id.D,
              Dp = _globs$glob$id.Dp;
          glob.D = vec.round(vec.add(D, delta));
          glob.Dp = vec.round(vec.add(Dp, delta));
        } // Move nodes

      } catch (err) {
        _iterator5.e(err);
      } finally {
        _iterator5.f();
      }

      var _iterator6 = _createForOfIteratorHelper(data.selectedNodes),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var nodeId = _step6.value;
          var node = nodes[nodeId];
          node.point = vec.round(vec.add(nodes[node.id].point, delta), 2);
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }

      updateGlobPoints(data);
    },
    undo: function undo(data) {
      for (var nodeId in pasted.nodes) {
        delete data.nodes[nodeId];
      }

      for (var globId in pasted.globs) {
        delete data.globs[globId];
      }

      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
    }
  }));
}
export function cloneSelection(data, clones, snapshot) {
  var _current3 = current(data),
      sNodes = _current3.nodes,
      sGlobs = _current3.globs,
      sSelectedNodes = _current3.selectedNodes,
      sSelectedGlobs = _current3.selectedGlobs;

  var sCloneNodes = clones.nodes.map(function (id) {
    return sNodes[id];
  });
  var sCloneGlobs = clones.globs.map(function (id) {
    return sGlobs[id];
  });
  gtag.event({
    action: "cloned_selection",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Move,
    manualSelection: true,
    "do": function _do(data, initial) {
      // When first executed, the items will already be in the correct position
      if (initial) return;

      var _iterator7 = _createForOfIteratorHelper(sCloneNodes),
          _step7;

      try {
        for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
          var node = _step7.value;
          data.nodes[node.id] = node;
        }
      } catch (err) {
        _iterator7.e(err);
      } finally {
        _iterator7.f();
      }

      var _iterator8 = _createForOfIteratorHelper(sCloneGlobs),
          _step8;

      try {
        for (_iterator8.s(); !(_step8 = _iterator8.n()).done;) {
          var glob = _step8.value;
          data.globs[glob.id] = glob;
        }
      } catch (err) {
        _iterator8.e(err);
      } finally {
        _iterator8.f();
      }

      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
      data.selectedNodes = sSelectedNodes;
      data.selectedGlobs = sSelectedGlobs;
      data.hoveredNodes = clones.hoveredNodes;
      data.hoveredGlobs = clones.hoveredGlobs;
    },
    undo: function undo(data) {
      var _iterator9 = _createForOfIteratorHelper(sCloneNodes),
          _step9;

      try {
        for (_iterator9.s(); !(_step9 = _iterator9.n()).done;) {
          var node = _step9.value;
          delete data.nodes[node.id];
        }
      } catch (err) {
        _iterator9.e(err);
      } finally {
        _iterator9.f();
      }

      var _iterator10 = _createForOfIteratorHelper(sCloneGlobs),
          _step10;

      try {
        for (_iterator10.s(); !(_step10 = _iterator10.n()).done;) {
          var glob = _step10.value;
          delete data.globs[glob.id];
        }
      } catch (err) {
        _iterator10.e(err);
      } finally {
        _iterator10.f();
      }

      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
      data.selectedNodes = snapshot.selectedNodes;
      data.selectedGlobs = snapshot.selectedGlobs;
      data.hoveredNodes = [];
      data.hoveredGlobs = [];
    }
  }));
}
export function moveSelection(data, delta, snapshot, handleSnapshot) {
  var sSnapshot = MoveSession.getSnapshot(data);
  var sGlobHandles = handleSnapshot ? Object.fromEntries(Object.keys(handleSnapshot).map(function (globId) {
    return [globId, {
      D: _toConsumableArray(data.globs[globId].D),
      Dp: _toConsumableArray(data.globs[globId].Dp)
    }];
  })) : {};
  gtag.event({
    action: "moved_selection",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Move,
    "do": function _do(data, initial) {
      // When first executed, the items will already be in the correct position
      if (initial) return;
      MoveSession.moveSelection(data, delta, snapshot);
      var globs = data.globs;

      for (var globId in sGlobHandles) {
        globs[globId].D = sGlobHandles[globId].D;
        globs[globId].Dp = sGlobHandles[globId].Dp;
      }

      updateGlobPoints(data);
    },
    undo: function undo(data) {
      var globs = data.globs;
      MoveSession.moveSelection(data, vec.neg(delta), sSnapshot);

      for (var globId in handleSnapshot) {
        globs[globId].D = handleSnapshot[globId].D;
        globs[globId].Dp = handleSnapshot[globId].Dp;
      }

      updateGlobPoints(data);
    }
  }));
}
export function moveHandle(data, id, initial, current) {
  gtag.event({
    action: "moved_handle",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Move,
    "do": function _do(data, initial) {
      if (initial) return;
      var glob = data.globs[id];

      var _glob$nodes$map = glob.nodes.map(function (id) {
        return data.nodes[id];
      }),
          _glob$nodes$map2 = _slicedToArray(_glob$nodes$map, 2),
          start = _glob$nodes$map2[0],
          end = _glob$nodes$map2[1];

      glob.D = current.D;
      glob.Dp = current.Dp;

      try {
        // Rebuild the glob points
        glob.points = getGlobPoints(glob, start, end);
      } catch (e) {
        glob.points = null;
      }
    },
    undo: function undo(data) {
      data.globs[id].D = initial.D;
      data.globs[id].Dp = initial.Dp;
      var glob = data.globs[id];

      var _glob$nodes$map3 = glob.nodes.map(function (id) {
        return data.nodes[id];
      }),
          _glob$nodes$map4 = _slicedToArray(_glob$nodes$map3, 2),
          start = _glob$nodes$map4[0],
          end = _glob$nodes$map4[1];

      try {
        glob.points = getGlobPoints(glob, start, end);
      } catch (e) {
        glob.points = null;
      }
    }
  }));
}
export function moveAnchor(data, globId, initial) {
  var current = AnchorSession.getSnapshot(data, globId);
  gtag.event({
    action: "moved_anchor",
    category: "canvas_actions",
    label: "",
    value: 0
  }); // We need a way to restore the nodes from when the drag began and ended

  history.execute(data, new Command({
    type: CommandType.MoveAnchor,
    "do": function _do(data, initial) {
      if (initial) return;
      var glob = data.globs[globId];
      Object.assign(glob, current);

      var _glob$nodes$map5 = glob.nodes.map(function (id) {
        return data.nodes[id];
      }),
          _glob$nodes$map6 = _slicedToArray(_glob$nodes$map5, 2),
          start = _glob$nodes$map6[0],
          end = _glob$nodes$map6[1];

      glob.points = getGlobPoints(glob, start, end);
    },
    undo: function undo(data) {
      var glob = data.globs[globId];
      Object.assign(glob, initial);

      var _glob$nodes$map7 = glob.nodes.map(function (id) {
        return data.nodes[id];
      }),
          _glob$nodes$map8 = _slicedToArray(_glob$nodes$map7, 2),
          start = _glob$nodes$map8[0],
          end = _glob$nodes$map8[1];

      glob.points = getGlobPoints(glob, start, end);
    }
  }));
}
export function splitGlob(data, id) {
  var _current4 = current(data),
      sGlobs = _current4.globs,
      sCamera = _current4.camera,
      sNodes = _current4.nodes;

  var newStartNode, D0, D1, D0p, D1p, a0, b0, a0p, b0p, a1, b1, a1p, b1p;
  var glob = sGlobs[id];
  var oldGlob = glob;
  var point = screenToWorld(inputs.pointer.point, sCamera);
  var _glob$points = glob.points,
      E0 = _glob$points.E0,
      E0p = _glob$points.E0p,
      E1 = _glob$points.E1,
      E1p = _glob$points.E1p,
      F0 = _glob$points.F0,
      F1 = _glob$points.F1,
      F0p = _glob$points.F0p,
      F1p = _glob$points.F1p,
      D = _glob$points.D,
      Dp = _glob$points.Dp; // Points on curve

  var closestP = getClosestPointOnCurve(point, E0, F0, F1, E1);
  var closestPp = getClosestPointOnCurve(point, E0p, F0p, F1p, E1p);

  if (!(closestP.point && closestPp.point)) {
    console.warn("Could not find closest points.");
    return;
  }

  var P = closestP.point;
  var Pp = closestPp.point; // Find the circle

  var C, r; // Normals

  var N = getNormalOnCurve(E0, F0, F1, E1, closestP.t);
  var Np = getNormalOnCurve(E0p, F0p, F1p, E1p, closestPp.t);

  if (Math.abs(N[0] - Np[0]) < 0.001 && Math.abs(N[1] - Np[1]) < 0.001) {
    // Lines are parallel
    var _glob$nodes$map9 = glob.nodes.map(function (id) {
      return sNodes[id];
    }),
        _glob$nodes$map10 = _slicedToArray(_glob$nodes$map9, 2),
        start = _glob$nodes$map10[0],
        end = _glob$nodes$map10[1];

    var _point = vec.med(closestP.point, closestPp.point);

    newStartNode = getNewNode(_point, (start.radius + end.radius) / 2);
    D0 = vec.med(E0, closestP.point);
    D1 = vec.med(closestP.point, E1);
    D0p = vec.med(E0p, closestPp.point);
    D1p = vec.med(closestPp.point, E1p);
    a0 = glob.a;
    b0 = glob.b;
    a0p = glob.ap;
    b0p = glob.bp;
    a1 = glob.a;
    b1 = glob.b;
    a1p = glob.ap;
    b1p = glob.bp;
  } else {
    var center = vec.med(N, Np);

    try {
      // Find intersection between normals
      var intA = getLineLineIntersection(vec.sub(P, vec.mul(N, 1000000)), vec.add(P, vec.mul(N, 1000000)), vec.sub(Pp, vec.mul(Np, 1000000)), vec.add(Pp, vec.mul(Np, 1000000)));
      var L0 = vec.sub(P, vec.mul(vec.per(N), 10000000));
      var L1 = vec.add(P, vec.mul(vec.per(N), 10000000)); // Center intersection

      var intB = getLineLineIntersection(L0, L1, vec.sub(intA, vec.mul(center, 10000000)), vec.add(intA, vec.mul(center, 10000000))); // Create a circle at the point of intersection. The distance
      // will be the same to either point.

      C = intB;
      r = vec.dist(P, C);
    } catch (e) {
      // If the lines are parallel, we won't have an intersection.
      // In this case, create a circle between the two points.
      C = vec.med(P, Pp);
      r = vec.dist(P, Pp) / 2;
    } // Find an intersection between E0->D and L0->inverted D


    var PL = [vec.sub(P, vec.mul(N, 10000000)), vec.add(P, vec.mul(N, 10000000))];
    var PLp = [vec.sub(Pp, vec.mul(Np, 10000000)), vec.add(Pp, vec.mul(Np, 10000000))];
    D0 = getLineLineIntersection(PL[0], PL[1], E0, D);
    D1 = getLineLineIntersection(PL[0], PL[1], E1, D);
    D0p = getLineLineIntersection(PLp[0], PLp[1], E0p, Dp);
    D1p = getLineLineIntersection(PLp[0], PLp[1], E1p, Dp);

    if (!(D0 && D1 && D0p && D1p)) {
      console.warn("Could not split glob there.");
      return;
    } // The radio of distances between old and new handles


    var d0 = vec.dist(E0, D0) / vec.dist(E0, D);
    var d0p = vec.dist(E0p, D0p) / vec.dist(E0p, Dp);
    var d1 = vec.dist(E1, D1) / vec.dist(E1, D);
    var d1p = vec.dist(E1p, D1p) / vec.dist(E1p, Dp); // Not sure why this part works

    var t0 = 0.75 - d0 * 0.25;
    var t0p = 0.75 - d0p * 0.25;
    var t1 = 0.75 - d1 * 0.25;
    var t1p = 0.75 - d1p * 0.25;
    a0 = t0;
    b0 = t0;
    a0p = t0p;
    b0p = t0p;
    a1 = t1;
    b1 = t1;
    a1p = t1p;
    b1p = t1p;
    newStartNode = getNewNode(C, r);
  }

  var newGlob = _objectSpread(_objectSpread({}, getNewGlob(newStartNode, data.nodes[glob.nodes[1]])), {}, {
    D: D1,
    Dp: D1p,
    a: a1,
    b: b1,
    ap: a1p,
    bp: b1p
  });

  newGlob.points = getGlobPoints(newGlob, newStartNode, data.nodes[glob.nodes[1]]);
  gtag.event({
    action: "split_glob",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Split,
    "do": function _do(data) {
      // Old glob
      var glob = data.globs[oldGlob.id];
      glob.nodes[1] = newStartNode.id;
      Object.assign(glob, {
        D: D0,
        Dp: D0p,
        a: a0,
        b: b0,
        ap: a0p,
        bp: b0p
      });
      glob.points = getGlobPoints(glob, data.nodes[glob.nodes[0]], newStartNode); // New Glob

      data.nodeIds.push(newStartNode.id);
      data.nodes[newStartNode.id] = newStartNode;
      data.globIds.push(newGlob.id);
      data.globs[newGlob.id] = newGlob;
      data.hoveredGlobs = [];
      data.hoveredNodes = [newStartNode.id];
      data.selectedNodes = [newStartNode.id];
    },
    undo: function undo(data) {
      Object.assign(data.globs[oldGlob.id], oldGlob);
      delete data.nodes[newStartNode.id];
      data.nodeIds = Object.keys(data.nodes);
      delete data.globs[newGlob.id];
      data.globIds = Object.keys(data.globs);
    }
  }));
} // Reordering

export function reorderGlobs(data, from, to) {
  var sGlobIds = _toConsumableArray(data.globIds);

  gtag.event({
    action: "reordered_globs",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ReorderGlobs,
    "do": function _do(data) {
      var id = data.globIds.splice(from, 1);
      data.globIds.splice(to, 0, id[0]);
    },
    undo: function undo(data) {
      data.globIds = sGlobIds;
    }
  }));
}
export function reorderNodes(data, from, to) {
  var sNodeIds = _toConsumableArray(data.nodeIds);

  history.execute(data, new Command({
    type: CommandType.ReorderGlobs,
    "do": function _do(data) {
      var id = data.nodeIds.splice(from, 1);
      data.nodeIds.splice(to, 0, id[0]);
    },
    undo: function undo(data) {
      data.nodeIds = sNodeIds;
    }
  }));
}
export function deleteSelection(data) {
  var _current5 = current(data),
      sGlobs = _current5.globs,
      sNodes = _current5.nodes,
      sSelectedGlobIds = _current5.selectedGlobs,
      sSelectedNodeIds = _current5.selectedNodes;

  var deletedGlobIds = new Set(sSelectedGlobIds);
  var deletedNodeIds = new Set(sSelectedNodeIds);

  for (var globId in deletedGlobIds) {
    var glob = sGlobs[globId];
    deletedNodeIds.add(glob.nodes[0]);
    deletedNodeIds.add(glob.nodes[1]);
  }

  for (var _globId in sGlobs) {
    var _glob = sGlobs[_globId];

    if (_glob.nodes.some(function (nodeId) {
      return deletedNodeIds.has(nodeId);
    })) {
      deletedGlobIds.add(_globId);
    }
  }

  gtag.event({
    action: "deleted_selection",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Delete,
    "do": function _do(data) {
      deletedGlobIds.forEach(function (id) {
        return delete data.globs[id];
      });
      data.globIds = Object.keys(data.globs);
      deletedNodeIds.forEach(function (id) {
        return delete data.nodes[id];
      });
      data.nodeIds = Object.keys(data.nodes);
      data.selectedNodes = [];
      data.selectedGlobs = [];
    },
    undo: function undo(data) {
      deletedGlobIds.forEach(function (id) {
        return data.globs[id] = sGlobs[id];
      });
      data.globIds = Object.keys(data.globs);
      deletedNodeIds.forEach(function (id) {
        return data.nodes[id] = sNodes[id];
      });
      data.nodeIds = Object.keys(data.nodes);
    }
  }));
}
export function toggleSelectionLocked(data) {
  var selectedNodes = _toConsumableArray(data.selectedNodes);

  var currentLocked = Object.fromEntries(Object.entries(data.nodes).map(function (_ref2) {
    var _ref3 = _slicedToArray(_ref2, 2),
        id = _ref3[0],
        node = _ref3[1];

    return [id, node.locked];
  }));
  gtag.event({
    action: "toggled_locked",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ToggleLocked,
    "do": function _do(data) {
      var locked = !selectedNodes.every(function (id) {
        return data.nodes[id].locked;
      });

      var _iterator11 = _createForOfIteratorHelper(selectedNodes),
          _step11;

      try {
        for (_iterator11.s(); !(_step11 = _iterator11.n()).done;) {
          var id = _step11.value;
          data.nodes[id].locked = locked;
        }
      } catch (err) {
        _iterator11.e(err);
      } finally {
        _iterator11.f();
      }
    },
    undo: function undo(data) {
      for (var id in currentLocked) {
        data.nodes[id].locked = currentLocked[id];
      }
    }
  }));
}
export function updateGlobOptions(data, options) {
  var snapshot = getSelectionSnapshot(data);

  var sGlobIds = _toConsumableArray(data.selectedGlobs);

  gtag.event({
    action: "updated_glob_options",
    category: "panel_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ChangeBounds,
    "do": function _do(data) {
      var _iterator12 = _createForOfIteratorHelper(sGlobIds),
          _step12;

      try {
        for (_iterator12.s(); !(_step12 = _iterator12.n()).done;) {
          var globId = _step12.value;
          var glob = data.globs[globId];
          Object.assign(glob, options);
        }
      } catch (err) {
        _iterator12.e(err);
      } finally {
        _iterator12.f();
      }

      updateGlobPoints(data);
    },
    undo: function undo(data) {
      var _iterator13 = _createForOfIteratorHelper(sGlobIds),
          _step13;

      try {
        for (_iterator13.s(); !(_step13 = _iterator13.n()).done;) {
          var globId = _step13.value;
          var glob = data.globs[globId];
          Object.assign(glob, snapshot.globs[globId]);
        }
      } catch (err) {
        _iterator13.e(err);
      } finally {
        _iterator13.f();
      }

      updateGlobPoints(data);
    }
  }));
}
export function moveBounds(data, delta) {
  var sGlobIds = _toConsumableArray(data.selectedGlobs);

  var nodeIdsToMove = new Set(data.selectedNodes);

  var _iterator14 = _createForOfIteratorHelper(sGlobIds),
      _step14;

  try {
    for (_iterator14.s(); !(_step14 = _iterator14.n()).done;) {
      var globId = _step14.value;
      var glob = data.globs[globId];
      nodeIdsToMove.add(glob.nodes[0]);
      nodeIdsToMove.add(glob.nodes[1]);
    }
  } catch (err) {
    _iterator14.e(err);
  } finally {
    _iterator14.f();
  }

  gtag.event({
    action: "moved_bounds",
    category: "panel_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ChangeBounds,
    "do": function _do(data) {
      nodeIdsToMove.forEach(function (nodeId) {
        var node = data.nodes[nodeId];
        node.point = vec.add(node.point, delta);
      });

      var _iterator15 = _createForOfIteratorHelper(sGlobIds),
          _step15;

      try {
        for (_iterator15.s(); !(_step15 = _iterator15.n()).done;) {
          var globId = _step15.value;
          var glob = data.globs[globId];
          glob.D = vec.add(glob.D, delta);
          glob.Dp = vec.add(glob.Dp, delta);
        }
      } catch (err) {
        _iterator15.e(err);
      } finally {
        _iterator15.f();
      }

      updateGlobPoints(data);
    },
    undo: function undo(data) {
      nodeIdsToMove.forEach(function (nodeId) {
        var node = data.nodes[nodeId];
        node.point = vec.sub(node.point, delta);
      });

      var _iterator16 = _createForOfIteratorHelper(sGlobIds),
          _step16;

      try {
        for (_iterator16.s(); !(_step16 = _iterator16.n()).done;) {
          var globId = _step16.value;
          var glob = data.globs[globId];
          glob.D = vec.sub(glob.D, delta);
          glob.Dp = vec.sub(glob.Dp, delta);
        }
      } catch (err) {
        _iterator16.e(err);
      } finally {
        _iterator16.f();
      }

      updateGlobPoints(data);
    }
  }));
}
export function rotateSelection(data, center, angle, snapshot) {
  gtag.event({
    action: "rotated_bounds",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ChangeBounds,
    "do": function _do(data, initial) {
      if (initial) return;
      RotateSession.rotate(data, center, angle, snapshot);
      updateGlobPoints(data);
    },
    undo: function undo(data) {
      for (var id in snapshot.nodes) {
        var sNode = snapshot.nodes[id];
        var node = data.nodes[id];
        node.point = sNode.point;
        node.radius = sNode.radius;
      }

      for (var _id in snapshot.globs) {
        var sGlob = snapshot.globs[_id];
        var glob = data.globs[_id];
        Object.assign(glob, sGlob);
      }

      updateGlobPoints(data);
    }
  }));
}
export function transformBounds(data, type, value, restore, snapshot, preserveRadii) {
  var current = TransformSession.getSnapshot(data);
  gtag.event({
    action: "transformed_bounds",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ChangeBounds,
    "do": function _do(data, initial) {
      if (initial) return;
      var _snapshot$bounds = snapshot.bounds,
          x0 = _snapshot$bounds.minX,
          y0 = _snapshot$bounds.minY,
          x1 = _snapshot$bounds.maxX,
          y1 = _snapshot$bounds.maxY;
      var _snapshot$bounds2 = snapshot.bounds,
          mx = _snapshot$bounds2.maxX,
          my = _snapshot$bounds2.maxY,
          mw = _snapshot$bounds2.width,
          mh = _snapshot$bounds2.height;
      TransformSession.transformSelection(data, type, current.point, value, {
        x0: x0,
        y0: y0,
        x1: x1,
        y1: y1,
        mx: mx,
        my: my,
        mw: mw,
        mh: mh
      }, snapshot, preserveRadii);
      updateGlobPoints(data);
    },
    undo: function undo(data) {
      for (var id in restore.nodes) {
        var sNode = restore.nodes[id];
        var node = data.nodes[id];
        node.point = sNode.point;
        node.radius = sNode.radius;
      }

      for (var _id2 in restore.globs) {
        var sGlob = restore.globs[_id2];
        var glob = data.globs[_id2];
        Object.assign(glob, sGlob);
      }

      updateGlobPoints(data);
    }
  }));
} // This is only for resizing to specific values, e.g. via the props panel

export function resizeBounds(data, size) {
  var sNodeIds = _toConsumableArray(data.selectedNodes);

  var sGlobIds = _toConsumableArray(data.selectedGlobs);

  var bounds = getSelectedBoundingBox(data);
  var delta = [size[0] ? size[0] - bounds.width : 0, size[1] ? size[1] - bounds.height : 0];
  gtag.event({
    action: "resized_bounds",
    category: "panel_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ChangeBounds,
    "do": function _do(data) {
      getResizedBounds(sNodeIds.map(function (id) {
        return data.nodes[id];
      }), sGlobIds.map(function (id) {
        return data.globs[id];
      }), bounds, [0, 0], delta, true);
      updateGlobPoints(data);
    },
    undo: function undo(data) {
      getResizedBounds(sNodeIds.map(function (id) {
        return data.nodes[id];
      }), sGlobIds.map(function (id) {
        return data.globs[id];
      }), bounds, [0, 0], vec.neg(delta), true);
      updateGlobPoints(data);
    }
  }));
}
export function resizeNode(data, snapshot, handleSnapshot) {
  var current = getSelectionSnapshot(data);
  var sGlobHandles = Object.fromEntries(Object.keys(handleSnapshot).map(function (globId) {
    return [globId, {
      D: _toConsumableArray(data.globs[globId].D),
      Dp: _toConsumableArray(data.globs[globId].Dp)
    }];
  }));
  gtag.event({
    action: "resized_node",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.Move,
    "do": function _do(data, initial) {
      if (initial) return;
      var nodes = data.nodes,
          globs = data.globs;

      for (var nodeId in snapshot.nodes) {
        nodes[nodeId].radius = current.nodes[nodeId].radius;
      }

      for (var globId in sGlobHandles) {
        globs[globId].D = sGlobHandles[globId].D;
        globs[globId].Dp = sGlobHandles[globId].Dp;
      }

      updateGlobPoints(data);
      updateGlobPoints(data);
    },
    undo: function undo(data) {
      var nodes = data.nodes,
          globs = data.globs;

      for (var nodeId in snapshot.nodes) {
        nodes[nodeId].radius = snapshot.nodes[nodeId].radius;
      }

      for (var globId in handleSnapshot) {
        globs[globId].D = handleSnapshot[globId].D;
        globs[globId].Dp = handleSnapshot[globId].Dp;
      }

      updateGlobPoints(data);
    }
  }));
}
export function toggleSelectedNodesCap(data, id) {
  var cap = data.nodes[id].cap;
  gtag.event({
    action: "toggled_cap",
    category: "canvas_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.ToggleCap,
    "do": function _do(data) {
      var node = data.nodes[id];
      node.cap = cap === "round" ? "flat" : "round";
    },
    undo: function undo(data) {
      var node = data.nodes[id];
      node.cap = cap;
    }
  }));
}
export function setPropertyOnSelectedNodes(data, change) {
  var _change$x = change.x,
      x = _change$x === void 0 ? null : _change$x,
      _change$y = change.y,
      y = _change$y === void 0 ? null : _change$y,
      _change$r = change.r,
      r = _change$r === void 0 ? null : _change$r,
      _change$cap = change.cap,
      cap = _change$cap === void 0 ? null : _change$cap,
      _change$locked = change.locked,
      locked = _change$locked === void 0 ? null : _change$locked;
  var sNodes = Object.fromEntries(data.selectedNodes.map(function (id) {
    var _data$nodes$id = data.nodes[id],
        point = _data$nodes$id.point,
        radius = _data$nodes$id.radius,
        cap = _data$nodes$id.cap;
    return [id, {
      point: _toConsumableArray(point),
      radius: radius,
      cap: cap,
      locked: locked
    }];
  }));
  gtag.event({
    action: "set_property",
    category: "panel_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.SetProperty,
    "do": function _do(data) {
      for (var key in sNodes) {
        var node = data.nodes[key];
        if (x !== null) node.point[0] = x;
        if (y !== null) node.point[1] = y;
        if (r !== null) node.radius = r;
        if (cap !== null) node.cap = cap;
        if (locked !== null) node.locked = locked;
      }

      updateGlobPoints(data);
    },
    undo: function undo(data) {
      for (var key in sNodes) {
        var node = data.nodes[key];
        var sNode = sNodes[key];
        if (x !== null) node.point[0] = sNode.point[0];
        if (y !== null) node.point[1] = sNode.point[1];
        if (r !== null) node.radius = sNode.radius;
        if (cap !== null) node.cap = sNode.cap;
        if (locked !== null) node.locked = sNode.locked;
      }

      updateGlobPoints(data);
    }
  }));
}
export function setCanvasItems(data, items) {
  var _current6 = current(data),
      sGenerated = _current6.generated,
      sNodes = _current6.nodes,
      sGlobs = _current6.globs;

  gtag.event({
    action: "set_items_from_code",
    category: "code_actions",
    label: "",
    value: 0
  });
  history.execute(data, new Command({
    type: CommandType.SetItems,
    manualSelection: true,
    "do": function _do(data) {
      var _iterator17 = _createForOfIteratorHelper(sGenerated.nodeIds),
          _step17;

      try {
        for (_iterator17.s(); !(_step17 = _iterator17.n()).done;) {
          var _nodeId = _step17.value;
          delete data.nodes[_nodeId];
        }
      } catch (err) {
        _iterator17.e(err);
      } finally {
        _iterator17.f();
      }

      var _iterator18 = _createForOfIteratorHelper(sGenerated.globIds),
          _step18;

      try {
        for (_iterator18.s(); !(_step18 = _iterator18.n()).done;) {
          var _globId3 = _step18.value;
          delete data.globs[_globId3];
        }
      } catch (err) {
        _iterator18.e(err);
      } finally {
        _iterator18.f();
      }

      for (var nodeId in items.nodes) {
        data.nodes[nodeId] = items.nodes[nodeId];
      }

      for (var globId in items.globs) {
        var glob = items.globs[globId];
        glob.points = getGlobPoints(glob, data.nodes[glob.nodes[0]], data.nodes[glob.nodes[1]]);
        data.globs[globId] = glob;
      }

      var _loop = function _loop(_globId2) {
        if (!data.nodes[data.globs[_globId2].nodes[0]] || !data.nodes[data.globs[_globId2].nodes[1]]) {
          delete data.globs[_globId2];
          sGenerated.globIds = sGenerated.globIds.filter(function (id) {
            return id !== _globId2;
          });
        }
      };

      for (var _globId2 in data.globs) {
        _loop(_globId2);
      }

      data.generated.nodeIds = Object.keys(items.nodes);
      data.generated.globIds = Object.keys(items.globs);
      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
      data.selectedGlobs = [];
      data.selectedNodes = [];
      data.highlightGlobs = [];
      data.highlightNodes = [];
      data.hoveredGlobs = [];
      data.hoveredNodes = [];
    },
    undo: function undo(data) {
      for (var nodeId in items.nodes) {
        delete data.nodes[nodeId];
      }

      for (var globId in items.globs) {
        delete data.globs[globId];
      }

      var _iterator19 = _createForOfIteratorHelper(sGenerated.nodeIds),
          _step19;

      try {
        for (_iterator19.s(); !(_step19 = _iterator19.n()).done;) {
          var _nodeId2 = _step19.value;
          data.nodes[_nodeId2] = sNodes[_nodeId2];
        }
      } catch (err) {
        _iterator19.e(err);
      } finally {
        _iterator19.f();
      }

      var _iterator20 = _createForOfIteratorHelper(sGenerated.globIds),
          _step20;

      try {
        for (_iterator20.s(); !(_step20 = _iterator20.n()).done;) {
          var _globId4 = _step20.value;
          var glob = sGlobs[_globId4];
          glob.points = getGlobPoints(glob, data.nodes[glob.nodes[0]], data.nodes[glob.nodes[1]]);
          data.globs[_globId4] = glob;
        }
      } catch (err) {
        _iterator20.e(err);
      } finally {
        _iterator20.f();
      }

      data.generated.nodeIds = _toConsumableArray(sGenerated.nodeIds);
      data.generated.globIds = _toConsumableArray(sGenerated.globIds);
      data.nodeIds = Object.keys(data.nodes);
      data.globIds = Object.keys(data.globs);
      data.selectedGlobs = [];
      data.selectedNodes = [];
      data.highlightGlobs = [];
      data.highlightNodes = [];
      data.hoveredGlobs = [];
      data.hoveredNodes = [];
    }
  }));
} // export function template(data: IData) {
//   const snapshot = current(data)
//   history.execute(
//     data,
//     new Command({
//       type: CommandType.CreateNode,
//       do(data) {},
//       undo(data) {},
//     })
//   )
// }